import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { getEmployees } from "../services/EmployeeAPIServices";
import {
  getAllLeaveRequests,
  rejectLeaveRequest,
  getLeaveCounts,
  getLeaveTypeList,
  getEmpBasedLeaveType,
  addLeaveRequestAdmin,
  deleteLeave,
} from "../services/LeaveAPIServices";

import { Table, Modal, DatePicker, Space, Select, Tooltip, Radio, Switch } from "antd";
import moment from "moment";
import "antd/dist/antd.css";
import { CSVLink } from "react-csv";
import { LEAVE_STATUS, SHORT_LEAVE_TYPE_ID } from "../AppConstant";

function Leave() {
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  var current_year_month = "";
  const year = new Date().getFullYear();
  const month = new Date().getMonth() + 1;
  const date = new Date().getDate();

  if (month < 10) {
    current_year_month = year + "-0" + month;
  } else {
    current_year_month = year + "-" + month;
  }
  const spinner = document.getElementById("spinner");
  const [employeeList, setEmployeeList] = useState([]);
  const [leaveTable, setLeaveTable] = useState([]);
  const [emp_id, setEmployeeId] = useState("0");
  const [year_month, setMonth] = useState(current_year_month);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [leaveDate, setLeaveDate] = useState("");
  const [leaveStatus, setLeaveStatus] = useState("");
  const [leaveApprovedBy, setLeaveApprovedBy] = useState("");
  const [leaveRejectedBy, setLeaveRejectedBy] = useState("");
  const [leaveCanceledBy, setLeaveCanceledBy] = useState("");
  const [leaveDescription, setLeaveDescription] = useState("");
  const [canCancel, setCanCancel] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [request_id, setLeaveRequestId] = useState(0);
  const [rejectedReason, setRejectedReason] = useState("");
  const [workingDays, setWorkingDays] = useState(0);
  const [totalLeaves, setTotalLeaves] = useState(0);
  const [approvedTotLeaves, setApprovedTotLeaves] = useState(0);
  const [rejectedTotLeaves, setRejectededTotLeaves] = useState(0);
  const [pendingTotLeaves, setPendingTotLeaves] = useState(0);
  const [filterFrom, setFilterFrom] = useState("");
  const [filterTo, setFilterTo] = useState("");
  const [totLeaveList, setTotLeaveList] = useState([]);
  const [approvedLeaveList, setApprovedLeaveList] = useState([]);
  const [rejectedLeaveList, setRejectedLeaveList] = useState([]);
  const [pendingLeaveList, setPendingLeaveList] = useState([]);
  const [cancelededReason, setCanceledReason] = useState("");
  const [cancelededAt, setCanceledAt] = useState("");
  const [addLeaveModalTitle, setAddLeaveModalTitle] = useState("");
  const [addModalvisible, setAddModalVisible] = useState(false);
  const [addLeaveEmpId, setAddLeaveEmpId] = useState("");
  const [empLeaveTpes, setEmpLeaveTpes] = useState([]);
  const [selectedLeaveType, setSelectedLeaveType] = useState("");
  const [time_period, setTimePeriod] = useState("morning");
  const [isleaveTimeSet, setIsleaveTimeSet] = useState(true);
  const [isLeaveDayTypeSet, setLeaveDayTypeSet] = useState(true);
  const [leave_day_type, setLeaveDayType] = useState("full");
  const [isDisplayDayTypeSelection, setIsDisplayDayTypeSelection] = useState(false);
  const [isDisplayTimePeriodSelection, setIsDisplayTimePeriodSelection] = useState(false);
  const [approval_process, setApprovalProcess] = useState("APPROVED");
  const [leaveReason, setLeaveReason] = useState("");
  const [leaveAdminEnterReason, setLeaveAdminEnterReason] = useState("");
  const [leaveFrom, setLeaveFrom] = useState("");
  const [leaveTo, setLeaveTo] = useState("");
  const [leaveSingleDate, setLeaveSingleDate] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [exportFileName, setExportFileName] = useState("");
  const [selectedEmpName, setSelectedEmpName] = useState("");
  let setCustomPeriod = time_period;
  const columns = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
      sortDirections: ["descend"],
      hidden: true,
    },
    {
      key: "name",
      title: "Name",
      dataIndex: "name_with_initial",
      sorter: (a, b) => a.name_with_initial.length - b.name_with_initial.length,
    },
    {
      key: "leave_type",
      title: "Leave Type",
      render: (record) => {
        return (
          <>
            <div className="flex">
              <span
                style={{ background: record.color }}
                class={"badge badge-pill mb-1 mr-2"}
              >
                {" "}
                {record.leave_type}
              </span>
            </div>
          </>
        );
      },
    },
    {
      key: "to",
      title: "Day/s",
      render: (record) => {
        return (
          <>
            <div className="flex">
              <span>
                {moment(record.from).format("DD/MM/YYYY")} -{" "}
                {moment(record.to).format("DD/MM/YYYY")}
              </span>
            </div>
          </>
        );
      },
    },
    {
      key: "amount",
      title: "Amount",
      dataIndex: "amount",
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      key: "description",
      title: "Description",
      dataIndex: "description",
      render: (text) => {
        return (
          <>
            <div className="flex">
              <span>{truncate(text)} </span>
            </div>
          </>
        );
      },
    },
    {
      key: "approved_person",
      title: "Approved By",
      render: (record) => {
        return (
          <>
            <div className="flex">
              <span>
                {record.approved_person}
                <br /> {record.designation_name != null ? (<>({record.designation_name})</>) :''}
              </span>
            </div>
          </>
        );
      },
    },
    {
      key: "approval_process",
      title: "Approval Process",
      render: (record) => {
        return (
          <>
            {" "}
            <div className="d-flex justify-content-round" style={{ gap: '5px' }}>
              <button
                className="badge badge-pill bg-light-info action-btn"
                onClick={() => viewData(record)}
              >
                <i class="fa fa-eye" aria-hidden="true"></i>
              </button>
              {record.approval_process == "PENDING" ? (
                <span className="badge bg-light-warning">
                  {record.approval_process}
                </span>
              ) : record.approval_process == "APPROVED" ? (
                <span className="badge bg-light-success">
                  {record.approval_process}
                </span>
              ) : (
                <span className="badge bg-light-danger">
                  {record.approval_process}
                </span>
              )}
               {record.is_admin_entered == 1 ? (<>
                <button
                className="badge badge-pill bg-light-danger action-btn"
                onClick={() => deleteLeaveRequest(record.id)}
              >
                <i class="fa fa-trash" aria-hidden="true"></i>
              </button>
              </>):(<></>)}

            </div>
          </>
        );
      },
    },
  ].filter((item) => !item.hidden);

  useEffect(() => {
    getEmployees().then((response) => {
      setEmployeeList(response??[]);
    });

    const data = {
      filterFrom,
      filterTo,
      emp_id,
    };
    setLoading(true);
    getAllLeaveRequests(data).then((response) => {
      spinner.style.display = "none";
      setLeaveTable(response??[]);
      setLoading(false);
    });

    getLeaveCounts(data).then((response) => {
      setWorkingDays(response.workDays??0);
      setApprovedTotLeaves(response.total_approved_count??0);
      setTotalLeaves(response.total_leave_count??0);
      setPendingTotLeaves(response.total_pending_count??0);
      setRejectededTotLeaves(response.total_rejected_count??0);
      setTotLeaveList(response.total_leave_list_count??[]);
      setApprovedLeaveList(response.approved_leave_list_count??[]);
      setRejectedLeaveList(response.rejected_leave_list_count??[]);
      setPendingLeaveList(response.pending_leave_list_count??[]);
    });
  }, []);

  const truncate = (input) =>
    input?.length > 30 ? `${input.substring(0, 25)}...` : input;

  const viewData = (data) => {
    setLeaveStatus(data.approval_process);
    setLeaveDate(
      moment(data.from).format("DD/MM/YYYY") +
      " - " +
      moment(data.to).format("DD/MM/YYYY")
    );
    setLeaveDescription(data.description);
    setModalTitle(data.name_with_initial + " - " + data.leave_type);
    setLeaveRequestId(data.id);
    setRejectedReason(data.rejected_reason);
    setLeaveApprovedBy(
      data.designation_name != null ? (<>{data.approved_person} ({data.designation_name})</>):(<>{data.approved_person}</>)
    );
    setLeaveCanceledBy(data.canceled_person);
    setLeaveRejectedBy(data.reject_person);
    setCanceledReason(data.canceled_reason);
    setCanceledAt(data.canceled_at != null ? moment(data.canceled_at).format("DD/MM/YYYY") : "")

    var current_date = current_year_month + "-" + date;

    if (current_date < data.to && data.approval_process == "APPROVED") {
      setCanCancel(true);
    } else {
      setCanCancel(false);
    }

    setVisible(true);
  };

  const rejectLeave = (e) => {
    if (rejectReason == "" || rejectReason == null) {
      Swal.fire("warning", "Please enter reject reason. ", "warning");
    } else {
      const data = { request_id, rejectReason };

      rejectLeaveRequest(data).then((res) => {
        if (res.status_code == 1) {
          Swal.fire("success", "Successfully updated", "success");
          setLeaveRequestId(0);

          setVisible(false);
          setLoading(true);

          const leaveData = {
            filterFrom,
            filterTo,
            emp_id,
          };
          getAllLeaveRequests(leaveData).then((response) => {
            setLeaveTable(response);
            setLoading(false);
          });
        } else {
          Swal.fire("warning", " " + res.message, "warning");
        }
      });
    }
  };

  function changeDataLoad(event) {
    if (
      filterFrom == "" ||
      filterFrom == null ||
      filterTo == "" ||
      filterTo == "" ||
      emp_id == ""
    ) {
      Swal.fire("warning", "Please select employee & date range", "warning");
    } else {
      const data = {
        filterFrom,
        filterTo,
        emp_id,
      };

      setLoading(true);
      getAllLeaveRequests(data).then((response) => {
        setLeaveTable(response);
        setLoading(false);
      });

      getLeaveCounts(data).then((response) => {
        setWorkingDays(response.workDays);
        setApprovedTotLeaves(response.total_approved_count);
        setTotalLeaves(response.total_leave_count);
        setPendingTotLeaves(response.total_pending_count);
        setRejectededTotLeaves(response.total_rejected_count);
        setTotLeaveList(response.total_leave_list_count);
        setApprovedLeaveList(response.approved_leave_list_count);
        setRejectedLeaveList(response.rejected_leave_list_count);
        setPendingLeaveList(response.pending_leave_list_count);
      });
    }
  }
  const onChange = (dates, dateStrings) => {
    if (dates) {
      setFilterTo(dateStrings[1]);
      setFilterFrom(dateStrings[0]);
    } else {
      setFilterTo("");
      setFilterFrom("");
    }
  };

  const onSearch = (value) => {
    // console.log('search:', value);
  };

  const onChangeEmp = (value) => {
    setEmployeeId(value.key);
    var nameString = '' + value.label;
    var empName = nameString.replaceAll(',', '');
    setSelectedEmpName(empName);
    // console.log(`selected`, empName);

  };

  const changeTableData = (leaveType, status) => {
    let filterLeaveType = leaveType;
    let filterLeaveStatus = status;

    const filterData = {
      filterFrom,
      filterTo,
      emp_id,
      filterLeaveType,
      filterLeaveStatus,
    };
    setLoading(true);
    getLeaveTypeList(filterData).then((response) => {
      setLeaveTable(response.records);
      setLoading(false);
    });
  };

  useEffect(() => {
    isLeaveDayTypeSet ? setLeaveDayType('full') : setLeaveDayType('half');
    !isLeaveDayTypeSet ? setIsDisplayTimePeriodSelection(true) : setIsDisplayTimePeriodSelection(false);
  }, [isLeaveDayTypeSet]);

  useEffect(() => {

  }, [leave_day_type, isDisplayTimePeriodSelection]);


  useEffect(() => {
    if (selectedLeaveType == SHORT_LEAVE_TYPE_ID || leave_day_type == 'half') {
      setLeaveFrom(leaveSingleDate);
      setLeaveTo(leaveSingleDate)
    }

  }, [leaveSingleDate]);

  useEffect(() => {
    isleaveTimeSet ? setTimePeriod('morning') : setTimePeriod('evening');
    setCustomPeriod = time_period;
    if (selectedLeaveType == SHORT_LEAVE_TYPE_ID || leave_day_type == 'half') {
      setLeaveFrom(leaveSingleDate);
      setLeaveTo(leaveSingleDate)
    }
  }, [isleaveTimeSet]);


  const onChangeAddLeaveEmp = (value) => {
    setAddLeaveEmpId(value);
    const leave_add_emp = { emp_id: value };
    setEmpLeaveTpes([]);
    setSelectedLeaveType("");
    setIsDisplayTimePeriodSelection(false);
    setIsDisplayDayTypeSelection(false);
    getEmpBasedLeaveType(leave_add_emp).then((response) => {
      setEmpLeaveTpes(response);
    });
  };

  const onChangeLeaveTypes = (value) => {

    value == SHORT_LEAVE_TYPE_ID ? setIsDisplayTimePeriodSelection(true) : setIsDisplayTimePeriodSelection(false);
    value == SHORT_LEAVE_TYPE_ID ? setIsDisplayDayTypeSelection(false) : setIsDisplayDayTypeSelection(true);
    leave_day_type == 'half' ? setIsDisplayTimePeriodSelection(true) : '';
    setLeaveTo("");
    setLeaveFrom("");
    setLeaveSingleDate("");
    setSelectedLeaveType(value);

    if (value == SHORT_LEAVE_TYPE_ID || leave_day_type == 'half') {
      setLeaveFrom(leaveSingleDate);
      setLeaveTo(leaveSingleDate)
    }

  };

  const onChangeLeaveTime = () => {

    setIsleaveTimeSet((isleaveTimeSet) => !isleaveTimeSet);
    // isleaveTimeSet ? setTimePeriod('morning'): setTimePeriod('evening')
  };

  const onChangeLeaveDayType = () => {
    setLeaveTo("");
    setLeaveFrom("");
    setLeaveSingleDate("");
    setLeaveDayTypeSet((isLeaveDayTypeSet) => !isLeaveDayTypeSet);
  };

  const onChangeLeaveStatus = (value) => {
    setApprovalProcess(value);
  }

  const onChangeLeaveDates = (dates, dateStrings) => {
    if (dates) {
      setLeaveTo(dateStrings[1]);
      setLeaveFrom(dateStrings[0]);
    } else {
      setLeaveTo("");
      setLeaveFrom("");
    }
  };

  const onChangeSingleLeaveDate = (date, dateString) => {
    // console.log(dateString);
    setLeaveSingleDate(dateString)
  };

  const handleNewLeave = () => {
    setAddLeaveEmpId("");
    setEmpLeaveTpes([]);
    setSelectedLeaveType("");
    setIsleaveTimeSet(true);
    setLeaveDayTypeSet(true);
    setLeaveReason("")
    setApprovalProcess("APPROVED");
    setLeaveAdminEnterReason("");
    setIsDisplayTimePeriodSelection(false);
    setIsDisplayDayTypeSelection(false);
    setLeaveTo("");
    setLeaveFrom("");
    setLeaveSingleDate("");
    setAddLeaveModalTitle("Add New Leave");
    setAddModalVisible(true);
  }

  const saveNewLeaveRequest = (e) => {

    let setAmount = 0;

    // console.log('leaveSingleDate',leaveSingleDate)
    if (selectedLeaveType == SHORT_LEAVE_TYPE_ID) {
      setAmount = 0.25;
      setLeaveFrom(leaveSingleDate);
      setLeaveTo(leaveSingleDate)
    } else if (leave_day_type == 'half') {
      setAmount = 0.5;
      setLeaveFrom(leaveSingleDate);
      setLeaveTo(leaveSingleDate)
    } else if (leave_day_type == 'full') {
      setCustomPeriod = leave_day_type;
      const startDate = moment(leaveFrom);
      const timeEnd = moment(leaveTo);
      const diff = timeEnd.diff(startDate);
      const diffDuration = moment.duration(diff);
      setAmount = diffDuration.days() == 0 ? 1 : diffDuration.days() + 1;
    }
    if (leaveFrom == "" || leaveTo == "") {
      Swal.fire("Warning", "Please select leave date.", "warning");
    } else {
      const addLeaveData = {
        emp_id: addLeaveEmpId, leave_type: selectedLeaveType, from: leaveFrom, to: leaveTo
        , amount: setAmount, time_period: setCustomPeriod, leave_reason: leaveReason, leave_admin_enter_reason: leaveAdminEnterReason, approval_process: approval_process
      };

      addLeaveRequestAdmin(addLeaveData).then((response) => {
     
        if (response.status_code == 1) {
          Swal.fire("Success", "" + response.message, "success");
          setAddModalVisible(false);
        } else {
          Swal.fire("Warning", " " + response.message, "warning");
        }
      });
    }

  }


  const handleExport = () => {
    setExportFileName(filterFrom + '-' + filterTo + '(' + selectedEmpName + ')');
    let dataArrayset = [["Employee", ": " + selectedEmpName, "", "", "","Filtered Date Range : ","",moment(filterFrom).format("DD/MM/YYYY")+"-"+ moment(filterTo).format("DD/MM/YYYY")],
    ["", "", "", "", "", "", "", "", "", "", "", ""],
    ["EmpID", "Name", "", "Leave Type", "Leave From", "Leave To", "Amount", "Reason", "", "Approval Process", "Approved By", "", "Rejected By", "Rejected reason", "", "Cancelled By", "Cancelled Reason", "", "Admin Entered", "Admin entered reason", "Leave applied at"]];
    for (var i = 0; i < leaveTable.length; i++) {
      var newRow = [leaveTable[i].emp_id, leaveTable[i].name_with_initial, "", leaveTable[i].leave_type, leaveTable[i].from, leaveTable[i].to, leaveTable[i].amount,
      leaveTable[i].description, "", leaveTable[i].approval_process, leaveTable[i].approved_person, "", leaveTable[i].reject_person, leaveTable[i].rejected_reason, "",
      leaveTable[i].canceled_person, leaveTable[i].canceled_reason, "", leaveTable[i].is_admin_entered, leaveTable[i].admin_leave_enter_reason, moment(leaveTable[i].created_at).format("DD/MM/YYYY")]
      dataArrayset.push(newRow);
    }

    setCsvData(dataArrayset);
  };

  const deleteLeaveRequest = (value) => {
    const delete_json = {leave_id:value};
    deleteLeave(delete_json).then((response) => {
    
      if (response.status_code == 1) {
        Swal.fire("Success", "" + response.message, "success");
        changeDataLoad();
      } else {
        Swal.fire("Warning", " " + response.message, "warning");
      }
    });
  
  };

  return (
    <>
      <div className="row">
        <div className="col-8">
          <h3> Leave - Request View</h3>
        </div>
        <div className="col-4">
          <button
            type="button"
            className="btn btn-primary mb-3 d-block  float-right"
            onClick={(e) => handleNewLeave(e)}
          >
            Add New
          </button>
        </div>
      </div>
      <div className="users-list-filter px-2 align-center">
        <form>
          <div className="row border rounded py-2 mb-2 mx-n2">
            <div className="col-12 col-sm-6 col-lg-1"></div>
            <div className="col-12 col-sm-6 col-lg-4">
              <label for="users-list-verified">Employee</label>
              <fieldset className="form-group">
                <Select
                  labelInValue
                  showSearch
                  className="form-control"
                  optionFilterProp="children"
                  onSearch={onSearch}
                  onChange={onChangeEmp}
                  filterOption={(input, option) =>
                    option.children
                      .toString()
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  required
                >
                  <Option value="0">All Employees</Option>
                  {employeeList.map((item, i) => {
                    return (
                      <Option value={item.id}>
                        {item.first_name} {item.last_name} - {item.employment_type}
                      </Option>
                    );
                  })}
                </Select>
              </fieldset>
            </div>
            <div className="col-12 col-sm-6 col-lg-4">
              <label for="users-list-role">Date Period</label>
              <RangePicker
                className="form-control"
                ranges={{
                  Today: [moment(), moment()],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                }}
                onChange={onChange}
              />
            </div>

            <div className="col-12 col-sm-6 col-lg-2 d-flex align-items-center">
              <button
                type="button"
                className="btn btn-primary btn-block users-list-clear glow mb-1 mt-2"
                onClick={changeDataLoad}
              >
                <i className="ft-search"></i> Check
              </button>
            </div>
            <div className="col-12 col-sm-6 col-lg-1"></div>
          </div>
        </form>
      </div>
      <section id="minimal-statistics">
        <div className="row">
          <div className="col-xl-4 col-lg-6 col-12">
            <div className="row border card alert-light">
              <div className="card-content">
                <div className="card-body">
                  <a
                    class="col-12 btn"
                    style={{
                      background: "rgb(234, 240, 249)",
                    }}
                    onClick={() => changeTableData("ALL", "ALL")}
                  >
                    <h6 style={{ color: "black" }}>
                      Leaves &nbsp;{" "}
                      <span className="mb-1 warning">{totalLeaves}</span>
                    </h6>
                  </a>
                  <div className="text-center">
                    <div className="media-center align-self-center mt-1">
                      <div
                        className="btn-group-horizontal"
                        role="group"
                        aria-label="Vertical button group"
                      >
                        {totalLeaves == 0 ? (
                          <>
                            <p style={{ color: "black" }}>No any leaves</p>
                          </>
                        ) : (
                          totLeaveList.map((item, i) => {
                            return (
                              <>
                                {item.leave_count > 0 ? (<>
                                  <Tooltip title={item.short_name}>
                                    <button
                                      type="button"
                                      style={{
                                        background: item.color,
                                        color: "black",
                                      }}
                                      className={
                                        "btn btn-" +
                                        item.color +
                                        " bg-" +
                                        item.color +
                                        " text-left mr-1"
                                      }
                                      onClick={() =>
                                        changeTableData(item.short_name, "ALL")
                                      }
                                    >
                                      {item.leave_count}
                                    </button>
                                  </Tooltip>
                                </>) : (<></>)}

                              </>
                            );
                          })
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-12">
            <div className="row border card alert-light">
              <div className="card-content">
                <div className="card-body">
                  <a
                    class="col-12 btn"
                    style={{
                      background: "rgb(234, 240, 249)",
                    }}
                    onClick={() => changeTableData("ALL", "APPROVED")}
                  >
                    <h6 style={{ color: "black" }}>
                      Approved &nbsp;
                      <span className="mb-1 success">{approvedTotLeaves}</span>
                    </h6>
                  </a>
                  <div className="text-center">
                    <div className="media-center align-self-center mt-1">
                      <div
                        className="btn-group-horizontal"
                        role="group"
                        aria-label="Vertical button group"
                      >
                        {approvedTotLeaves == 0 ? (
                          <>
                            <p style={{ color: "black" }}>
                              No any approved leave
                            </p>
                          </>
                        ) : (
                          approvedLeaveList.map((item, i) => {
                            return (
                              <>
                                {item.leave_count > 0 ? (<>
                                  <Tooltip title={item.short_name}>
                                    <button
                                      type="button"
                                      style={{
                                        background: item.color,
                                        color: "black",
                                      }}
                                      className={
                                        "btn btn-" +
                                        item.color +
                                        " bg-" +
                                        item.color +
                                        " text-left mr-1"
                                      }
                                      onClick={() =>
                                        changeTableData(item.short_name, "APPROVED")
                                      }
                                    >
                                      {item.leave_count}
                                    </button>
                                  </Tooltip>
                                </>) : (<></>)}
                              </>
                            );
                          })
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-lg-6 col-12">
            <div className="row border card alert-light">
              <div className="card-content">
                <div className="card-body">
                  <a
                    class="col-12 btn"
                    style={{
                      background: "rgb(234, 240, 249)",
                    }}
                    onClick={() => changeTableData("ALL", "REJECTED")}
                  >
                    <h6 style={{ color: "black" }}>
                      Rejected &nbsp;
                      <span className="mb-1 danger">{rejectedTotLeaves}</span>
                    </h6>
                  </a>

                  <div className="text-center">
                    <div className="media-center align-self-center mt-1">
                      <div
                        className="btn-group-horizontal"
                        role="group"
                        aria-label="Vertical button group"
                      >
                        {rejectedTotLeaves == 0 ? (
                          <>
                            <p style={{ color: "black" }}>
                              No any rejected leave
                            </p>
                          </>
                        ) : (
                          rejectedLeaveList.map((item, i) => {
                            return (
                              <>
                                {item.leave_count > 0 ? (<>
                                  <Tooltip title={item.short_name}>
                                    <button
                                      type="button"
                                      style={{
                                        background: item.color,
                                        color: "black",
                                      }}
                                      className={
                                        "btn btn-" +
                                        item.color +
                                        " bg-" +
                                        item.color +
                                        " text-left mr-1"
                                      }
                                      onClick={() =>
                                        changeTableData(item.short_name, "REJECTED")
                                      }
                                    >
                                      {item.leave_count}
                                    </button>
                                  </Tooltip>
                                </>) : (<></>)}
                              </>
                            );
                          })
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-lg-6 col-12"></div>
          <div className="col-xl-4 col-lg-6 col-12">
            <div className="row border card alert-light">
              <div className="card-content">
                <div className="card-body">
                  <a
                    class="col-12 btn"
                    style={{
                      background: "rgb(234, 240, 249)",
                    }}
                    onClick={() => changeTableData("ALL", "PENDING")}
                  >
                    <h6 style={{ color: "black" }}>
                      Approval Pending &nbsp;
                      <span className="mb-1 info">{pendingTotLeaves}</span>
                    </h6>
                  </a>
                  <div className="text-center">
                    <div className="media-center align-self-center mt-1">
                      <div
                        className="btn-group-horizontal"
                        role="group"
                        aria-label="Vertical button group"
                      >
                        {pendingTotLeaves == 0 ? (
                          <>
                            <p style={{ color: "black" }}>
                              No any pending approvals
                            </p>
                          </>
                        ) : (
                          pendingLeaveList.map((item, i) => {
                            return (
                              <>
                                {item.leave_count > 0 ? (<>
                                  <Tooltip title={item.short_name}>
                                    <button
                                      type="button"
                                      style={{
                                        background: item.color,
                                        color: "black",
                                      }}
                                      className={
                                        "btn btn-" +
                                        item.color +
                                        " bg-" +
                                        item.color +
                                        " text-left mr-1"
                                      }
                                      onClick={() =>
                                        changeTableData(item.short_name, "PENDING")
                                      }
                                    >
                                      {item.leave_count}
                                    </button>
                                  </Tooltip>
                                </>) : (<></>)}
                              </>
                            );
                          })
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-12">
            <div className="row border card alert-light">
              <div className="card-content">
                <div className="card-body">
                  <div className="media pb-0 pt-1">
                    <div className="media-body text-left">
                      <h3 className="mb-3 danger">{workingDays}</h3>
                      <span style={{ color: "black" }}>Working Days</span>
                    </div>
                    <div className="media-right align-self-center">
                      <i className="ft-briefcase danger font-large-2 float-right"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-content">
                <div className="card-header">

                  <div className="text-right">
                    <CSVLink
                      data={csvData}
                      filename={`${exportFileName}.csv`}
                      onClick={(event, done) => {
                        handleExport();
                      }}
                    >
                      <i class="ft-download"></i> CSV
                    </CSVLink>

                  </div>

                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <div className="table">
                      <Table
                        loading={loading}
                        dataSource={leaveTable}
                        columns={columns}
                        pagination={{
                          pageSize: 10,
                          showSizeChanger: true,
                          position: ["bottomCenter"],
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        title={modalTitle}
        visible={visible}
        closable={false}
        onOk={() => setVisible(false)}
        cancelButtonProps={{
          style: {
            display: "none",
          },
        }}
      >
        <form>
          <center>
            <p>Day/s - {leaveDate}</p>
            <h6>{leaveDescription}</h6>

            {leaveStatus == "PENDING" ? (
              <span className="badge bg-light-warning">{leaveStatus}</span>
            ) : leaveStatus == "APPROVED" ? (
              <>
                <span className="badge bg-light-success mr-2">{leaveStatus}</span>
                <span>Approved By: {leaveApprovedBy}</span>
              </>
            ) : leaveStatus == "REJECTED" ? (
              <>
                <span className="badge bg-light-danger">{leaveStatus}</span> <br />
                <span>Rejected By: {leaveRejectedBy}</span>
                <div className="mt-2 p-2">
                  <h6>Reason:</h6>
                  <span>{rejectedReason}</span>
                </div>
              </>
            ) : (
              <>
                <span className="badge bg-light-danger mr-2">{leaveStatus}</span> <br />
                <span className="mt-1">Canceled by {leaveCanceledBy} at {cancelededAt}</span>
                <div className="mt-2 p-2">
                  <h6>Reason:</h6>
                  <span> {cancelededReason}</span>
                </div>

              </>
            )}

            {canCancel == true ? (
              <>

                <textarea
                  className="form-control m-1"
                  placeholder="Enter the reject reason"
                  col={30}
                  value={rejectReason}
                  onChange={(e) => setRejectReason(e.target.value)}
                ></textarea>

                <button
                  type="button"
                  onClick={() => rejectLeave()}
                  className="btn btn-danger"
                >
                  {" "}
                  Reject
                </button>
              </>
            ) : (
              <></>
            )}
          </center>
        </form>
      </Modal>

      <Modal
        title={addLeaveModalTitle}
        visible={addModalvisible}
        okText="Save"
        onCancel={() => setAddModalVisible(false)}
        onOk={(e) => {
          saveNewLeaveRequest(e);
        }}
      >
        <div className="row">
          <div className="col-12 col-sm-6 col-lg-6">
            <label for="users-list-verified">Employee <span style={{ color: "red" }}>*</span></label>
            <fieldset className="form-group">
              <Select
                showSearch
                value={addLeaveEmpId}
                className="form-control"
                optionFilterProp="children"
                onSearch={onSearch}
                onChange={onChangeAddLeaveEmp}
                filterOption={(input, option) =>
                  option.children
                    .toString()
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                required
              >
                <Option value="" disabled> Select employee</Option>
                {employeeList.map((item, i) => {
                  return (
                    <Option value={item.id}>
                      {item.first_name} {item.last_name} - {item.employment_type}
                    </Option>
                  );
                })}
              </Select>
            </fieldset>
          </div>
          <div className="col-12 col-sm-6 col-lg-6">
            <label for="users-list-verified">Leave Type <span style={{ color: "red" }}>*</span></label>
            <Select
              className="form-control"
              value={selectedLeaveType}
              onChange={onChangeLeaveTypes}
              required
            >
              {empLeaveTpes.map((item, i) => {
                return (
                  <Option value={item.id}>
                    {item.leave_type}
                  </Option>
                );
              })}
            </Select>
          </div>
          {isDisplayDayTypeSelection ? (<>
            <div className="col-6 col-sm-4 col-lg-4">
              <label for="users-list-verified">Duration <span style={{ color: "red" }}>*</span></label>
              <Switch checkedChildren="Full Day" unCheckedChildren="Half Day" defaultChecked onChange={onChangeLeaveDayType} checked={isLeaveDayTypeSet} />
            </div>
          </>) : (<></>)}



          {isDisplayTimePeriodSelection ? (<>
            <div className="col-6 col-sm-3 col-lg-3">
              <label for="users-list-verified">Time Period <span style={{ color: "red" }}>*</span></label>
              <Switch checkedChildren="Morning" unCheckedChildren="Evening" defaultChecked onChange={onChangeLeaveTime} checked={isleaveTimeSet} />
            </div>
          </>) : (<></>)}

          {leave_day_type == 'full' && isDisplayDayTypeSelection ? (<>
            <div className="col-12 col-sm-8 col-lg-8">
              <label for="users-list-verified">Date <span style={{ color: "red" }}>*</span></label>
              <RangePicker
                className="form-control"
                ranges={{
                  Today: [moment(), moment()],
                }}
                onChange={onChangeLeaveDates}
              />
            </div>
          </>) : (<>
            {leave_day_type == 'half' || isDisplayTimePeriodSelection ? (<>
              <div className="col-6 col-sm-5 col-lg-5">
                <label for="users-list-verified">Date <span style={{ color: "red" }}>*</span></label>
                <DatePicker className="form-control" value={leaveSingleDate !== "" ? moment(leaveSingleDate) : null} onChange={onChangeSingleLeaveDate} />
              </div>
            </>) : (<></>)}

          </>)}
          <div className="col-12 col-sm-12 col-lg-12">
            <label for="users-list-verified">Leave Reason <span style={{ color: "red" }}>*</span></label>
            <textarea className="form-control" value={leaveReason} onChange={(e) => setLeaveReason(e.target.value)}></textarea>
          </div>
          <div className="col-12 col-sm-12 col-lg-12">
            <label for="users-list-verified">Admin Enter Reason <span style={{ color: "red" }}>*</span></label>
            <textarea className="form-control" value={leaveAdminEnterReason} onChange={(e) => setLeaveAdminEnterReason(e.target.value)}></textarea>
          </div>
          <div className="col-6 col-sm-6 col-lg-6">
            <label for="users-list-verified">Status <span style={{ color: "red" }}>*</span></label>
            <Select
              className="form-control"
              onChange={onChangeLeaveStatus}
              value={approval_process}
              required
            >

              {LEAVE_STATUS.map((item, i) => {
                return (
                  <Option value={item.id}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </div>

        </div>

      </Modal>
    </>
  );
}
export default Leave;
