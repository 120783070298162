import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import { websiteUrl } from '../UrlPaths';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  flexDirection: 'column',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 150,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};


function FileUpload({submitDocuments,changeDropZoneList,buttonDisabled = false}) {
  const [files, setFiles] = useState([]);
  const {getRootProps, getInputProps} = useDropzone({
    multiple: true,
    autoFocus: false,
    accept: {
      'image/png': [],
      'image/jpeg': [],
      'image/jpg': [],
      'application/pdf':[]
    },
    onDrop: acceptedFiles => {
    setFiles([...files,...acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }))]);
    //   setFiles(acceptedFiles.map(file => Object.assign(file, {
    //     preview: URL.createObjectURL(file)
    //   })));
    }
  });
  
  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        {getExtension(file.name) != 'pdf' ? (<>
          <img
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => { URL.revokeObjectURL(file.preview) }}
        />
        </>):(<>
          <img
          src={`${websiteUrl}app-assets/img/pdf-format.png`}
          style={img}
          // Revoke data uri after image is loaded
          // onLoad={() => { URL.revokeObjectURL(file.preview) }}
        />
        </>)}
       
      </div>
      <div>
        <small>{file.name}</small>
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, []);

  useEffect(() => {
      setFiles(changeDropZoneList)
  }, [changeDropZoneList]);


  const clearDocuments = ()=>{
    setFiles([]);
  }

  function getExtension(filename) {
    return filename.split('.').pop()
}

  return (
    <section>
    <div className="dropzone-area">
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
      <aside style={thumbsContainer}>
        {thumbs}
      </aside>
    </div>
    <div>
    <button type='button' className="btn btn-sm btn-primary mt-2 float-right" onClick={() => submitDocuments(files)} >
    <i class="fa fa-right" aria-hidden="true"></i>  Upload</button>
    <button type='button' className="btn btn-sm btn-secondary mt-2 mr-1 float-right" onClick={clearDocuments} >
    Clear</button>
    </div>
    </section>
  );
}
  export default FileUpload;