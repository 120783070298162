import { useParams } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import { DatePicker, Select, Table, Switch, Modal } from "antd";
import moment from "moment";
import momentTZ from "moment-timezone";
import Swal from "sweetalert2";
import {
  getSpecificEmployeeData,
  editEmployeeData,
  editEmployeeBankData,
  getCompanyMasterLeaveTypes,
  assignLeaveTypes,
  getSpecificEmpLeaveTypes,
  uploadEmployeeDocument,
  getEmployeeDocumentTypes,
  deleteEmployeeDocument,
  getSpecificEmpBranches,
  addEmployeeBankData,
  storeOtherDetails,
  otherDocumentUpload,
  otherMultipleDocumentUpload
} from "../../services/EmployeeAPIServices";
import {
  getAllDesignations,
  getTeams,
  getEmpStatus,
} from "../../services/MasterFileAPIServices";
import FileUpload from "../../components/FileUpload";
import EmployeeDocumentCard from "../../components/EmployeeDocumentCard";
import { getActiveCompanyBranches } from "../../services/BranchAPIServices";
import EmployeeOtherTable from "../../components/EmployeeOtherTable";
import { CertificationColumns, COSColumns, EducationColumns, PassportColumns, RTWColumns, VisaColumns } from "../../data/TableColums";
import { EMP_CERTIFICATION_TYPE, EMP_COS_TYPE, EMP_EDUCATION_TYPE, EMP_PASSPORT_TYPE, EMP_PASSPORT_TYPE_ID, EMP_RTW_TYPE, EMP_VISA_TYPE, EMP_VISA_TYPE_ID } from "../../AppConstant";
import { Countries } from "../../data/countries";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import EmployeeOtherDocumentCard from "../../components/EmployeeOtherDocumentCard";
import BackButton from "../../components/BackButton";
const { YearPicker } = DatePicker;

function EditEmployee() {
  const { id } = useParams();
  const inputRef = useRef();
  const inputRef2 = useRef();
  const { Option } = Select;
  const spinner = document.getElementById("spinner");
  const [designationList, setDesignationList] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [empTypeList, setEmpTypeList] = useState([]);
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [middle_name, setMiddleName] = useState("");
  const [personTitle, setPersonTitle] = useState("");
  const [marital_status, setMaritalStatus] = useState("");
  const [nationality, setNationality] = useState("");
  const [emp_no, setEmpNo] = useState("");
  const [is_sponsored_emp, setIsSponsoredEmp] = useState("");
  const [insurance_no, setInsuranceNo] = useState("");
  const [city, setCity] = useState("");
  const [postcode, setPostCode] = useState("");
  const [country, setCountry] = useState("");
  const [workingHrs, setWorkingHrs] = useState("");
  const [reportingManager, setReportingManager] = useState("");
  const [jobContract, setJobContract] = useState("");
  const [socNo, setSocNo] = useState("");
  const [dob, setDob] = useState("");
  const [designation, setDesignation] = useState("");
  const [team, setTeam] = useState("");
  const [nic, setNic] = useState("");
  const [emp_type, setEmpType] = useState("");
  const [joined_date, setJoinedDate] = useState("");
  const [personal_email, setPersonalEmail] = useState("");
  const [mobile_no, setMobileNo] = useState("");
  const [home_tp_no, setHTNo] = useState("");
  const [permanent_address, setPermanentAddress] = useState("");
  const [location, setLocation] = useState("");
  const [kin_name, setKinName] = useState("");
  const [kin_contact_no, setKinContactNo] = useState("");
  const [kin_contact_no_2, setKinContactNo2] = useState("");
  const [kin_email, setKinEmail] = useState("");
  const [kin_relationship, setKinRelationship] = useState("");
  const [kin_address, setKinAddress] = useState("");
  const [kin_city, setKinCity] = useState("");
  const [kin_postcode, setKinPostcode] = useState("");
  const [kin_country, setKinCountry] = useState("");
  const [email, setOfsEmail] = useState("");
  const [img_path, setImagePath] = useState("");
  const [leader_or_coordinator, setLeadership] = useState("");
  const [temporary_address, setTemporaryAddress] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankBranch, setBankBranch] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [accountName, setAccountName] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [notes, setNotes] = useState("");
  const [leave_type_list, setLeaveTypesList] = useState([]);
  let [leave_types, setLeaveTypes] = useState([]);
  const [gender, setGender] = useState("");
  const [uploadedEmpDocuments, setUploadedEmpDocuments] = useState([]);
  const [documentTypeList, setDocumentTypeList] = useState([]);
  const [documentDataList, setDocumentDataList] = useState([]);
  const [branchesList, setBranchesList] = useState([]);
  let [selected_branches, setSelectedBranches] = useState([]);
  const [bankList, setBankList] = useState([]);
  const [banksLoading, setBanksLoading] = useState(false);
  const [bankEditingId, setBankEditingId] = useState("0");
  const [bankSortCode, setBankSortCode] = useState("");
  const [isPrimaryAccount, setIsPrimaryAccount] = useState(false);
  const [educationList, setEducationList] = useState([]);
  const [certificationList, setCertificationList] = useState([]);
  const [passportList, setPassportList] = useState([]);
  const [visaList, setVisaList] = useState([]);
  const [COSList, setCOSList] = useState([]);
  const [rtwList, setRtwList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalMethod, setModalMethod] = useState("");
  const [modalType, setModalType] = useState("");
  const [modalDataId, setModalDataId] = useState("0");
  const [user_id, setUserId] = useState("");
  // education
  const [eduDegree, setEduDegree] = useState("");
  const [eduInstitution, setEduInstitution] = useState("");
  const [eduMajor, setEduMajor] = useState("");
  const [eduYear, seteduYear] = useState("");
  // certificate 
  const [certiInstitution, setCertiInstitution] = useState("");
  const [certiName, setCertiName] = useState("");
  const [certiIssueDate, setCertiIssueDate] = useState("");
  const [certiDescription, setCertiDescription] = useState("");
  const [certiExpDate, setCertiExpDate] = useState("");
  // passport 
  const [passNumber, setPassNumber] = useState("");
  const [passIssuedCountry, setPassIssuedCountry] = useState("");
  const [passIssueDate, setPassIssueDate] = useState("");
  const [passCurrentActive, setPassCurrentActive] = useState("no");
  const [passExpDate, setPassExpDate] = useState("");
  // visa 
  const [visaNumber, setVisaNumber] = useState("");
  const [visaIssuedCountry, setVisaIssuedCountry] = useState("");
  const [visaIssueDate, setVisaIssueDate] = useState("");
  const [visaCurrentActive, setVisaCurrentActive] = useState("no");
  const [visaExpDate, setVisaExpDate] = useState("");
  const [visaType, setVisaType] = useState("");
  // cos
  const [cosLicenceNo, setCosLicenceNo] = useState("");
  const [cosSponsorName, setCosSponsorName] = useState("");
  const [cosCertiNo, setCosCertiNo] = useState("");
  const [cosCertiDate, setCosCertiDate] = useState("");
  const [cosAssignedDate, setCosAssignedDate] = useState("");
  const [cosExpDate, setCosExpDate] = useState("");
  const [cosSponsorNote, setCosSponsorNote] = useState("");
  const [cosAttachment, setCosAttachment] = useState("");
  // rtw
  const [rtwRef, setRtwRef] = useState("");
  const [rtwCheckDate, setRtwCeckDate] = useState("");
  const [rtwStatus, setRtwStatus] = useState("");
  const [rtwExpDate, setRtwExpDate] = useState("");
  const [rtwAttachment, setRtwAttachment] = useState("");
  // attachment
  const [attachmentPath, setAttachmentPath] = useState("");
  const [attachmentLabel, setAttachmentLabel] = useState("");
  const [docVisible, setDocVisible] = useState(false);
  const [viewModalTitle, setViewModalTitle] = useState("");
  const [otherDocArray, setOtherDocArray] = useState([]);
  const employee_id = id;


  const columns = [
    {
      key: "bank_name",
      title: "Bank Name",
      dataIndex: "bank_name",
    },
    {
      key: "branch",
      title: "Branch",
      dataIndex: "branch",
    },
    {
      key: "sort_code",
      title: "Sort Code",
      dataIndex: "sort_code",
    },
    {
      key: "account_no",
      title: "Account No",
      dataIndex: "account_no",
    },
    {
      key: "is_primary_account",
      title: "Primary Account",
      dataIndex: "is_primary_account",
      render: (text) => {
        return (
          <>
            {text == 1 ? (<>
              <span class="badge badge-pill badge-success mb-1 mr-2">Yes</span>
            </>) : (<>
              <span class="badge badge-pill badge-light mb-1 mr-2">No</span>
            </>)}
          </>
        );
      },
    },
    {
      key: "action",
      title: "",
      width: '10%',
      render: (record) => {
        return (
          <>
            <div className="flex">
              <button
                className="badge badge-pill bg-light-danger action-btn"
                style={{ color: "black" }}
                onClick={() => Edit(record)}
              >
                <i class="ft-edit"></i>
              </button>
            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {

    loadData();

    getCompanyMasterLeaveTypes().then((response) => {
      setLeaveTypesList(response.data.data);
    });

    getEmployeeDocumentTypes().then((res) => {
      setDocumentTypeList(res);
    })

    getAllDesignations().then((response) => {
      setDesignationList(response);
    });

    getTeams().then((response) => {
      setTeamList(response);
    });

    getEmpStatus().then((response) => {
      setEmpTypeList(response);
    });

    var emp_id = id;
    const passEmpId = { emp_id };
    getSpecificEmpLeaveTypes(passEmpId).then((response) => {
      setLeaveTypes(response.data.data.leaves);
    });


    getActiveCompanyBranches().then((res) => {
      setBranchesList(res)
    });

    getSpecificEmpBranches(passEmpId).then((response) => {
      setSelectedBranches(response.data.data.branches);
    });



  }, []);

  function loadData() {
    setBanksLoading(true);
    getSpecificEmployeeData(id).then((res) => {
      setFirstName(res.mainData.first_name);
      setMiddleName(res.mainData.middle_name);
      setLastName(res.mainData.last_name);
      setDob(res.mainData.birthday);
      setDesignation(res.mainData.designation);
      setTeam(res.mainData.team);
      setNic(res.mainData.nic);
      setEmpType(res.mainData.emp_type);
      setJoinedDate(res.mainData.join_date);
      setPersonalEmail(res.mainData.personal_email);
      setMobileNo(res.mainData.mobile_no);
      setHTNo(res.mainData.home_tp_no);
      setPermanentAddress(res.mainData.permanent_address);
      setOfsEmail(res.mainData.email);
      setKinName(res.mainData.kin_name);
      setKinContactNo(res.mainData.kin_contact_no);
      setKinContactNo2(res.mainData.kin_secondary_contact);
      setKinAddress(res.mainData.kin_address);
      setKinCity(res.mainData.kin_city);
      setKinEmail(res.mainData.kin_email);
      setKinPostcode(res.mainData.kin_post_code);
      setKinRelationship(res.mainData.kin_relationship);
      setKinCountry(res.mainData.kin_country);
      setImagePath(res.mainData.profile_img_path);
      setLeadership(res.mainData.leader_or_coordinator);
      setTemporaryAddress(res.mainData.temporary_address);
      setPersonTitle(res.mainData.title);
      setEmpNo(res.mainData.emp_number);
      setMaritalStatus(res.mainData.marital_status);
      setSocNo(res.mainData.soc_no);
      setInsuranceNo(res.mainData.national_insurance_no);
      setIsSponsoredEmp(res.mainData.is_sponsored_emp);
      setWorkingHrs(res.mainData.weekly_working_hrs);
      setJobContract(res.mainData.job_contract);
      setReportingManager(res.mainData.teamCoordinator);
      setNationality(res.mainData.nationality);
      setCity(res.mainData.city);
      setPostCode(res.mainData.postcode);
      setCountry(res.mainData.country);
      setUserId(res.mainData.user_id);
      setLocation(res.mainData.location);

      setUploadedEmpDocuments(res.documents);
      setBankList(res.banks);
      setBanksLoading(false);

      const findItemByKeyValue = (array, key, value) => {
        return array.find(item => item[key] === value);
      };
      let education = findItemByKeyValue(res.other, 'detail_type', EMP_EDUCATION_TYPE);
      let certificate = findItemByKeyValue(res.other, 'detail_type', EMP_CERTIFICATION_TYPE);
      let cos = findItemByKeyValue(res.other, 'detail_type', EMP_COS_TYPE);
      let visa = findItemByKeyValue(res.other, 'detail_type', EMP_VISA_TYPE);
      let passport = findItemByKeyValue(res.other, 'detail_type', EMP_PASSPORT_TYPE);
      let rtw = findItemByKeyValue(res.other, 'detail_type', EMP_RTW_TYPE);


      setEducationList(education ? JSON.parse(education.row_data) : [])
      setCertificationList(certificate ? JSON.parse(certificate.row_data) : [])
      setPassportList(passport ? JSON.parse(passport.row_data) : [])
      setCOSList(cos ? JSON.parse(cos.row_data) : [])
      setVisaList(visa ? JSON.parse(visa.row_data) : [])
      setRtwList(rtw ? JSON.parse(rtw.row_data) : [])

      setNotes(res.mainData.notes);
      setGender(res.mainData.gender);
      spinner.style.display = "none";
    });
  }



  const handleSubmitMainData = (e) => {
    e.preventDefault();
    let remove_empty_option = "";
    selected_branches = selected_branches.filter(function (item) {
      return item !== remove_empty_option
    })
    const data = {
      first_name,
      last_name,
      dob,
      mobile_no,
      nic,
      home_tp_no,
      personal_email,
      joined_date,
      emp_type,
      designation,
      team,
      employee_id,
      leader_or_coordinator,
      permanent_address,
      temporary_address,
      kin_name,
      kin_contact_no,
      gender,
      selected_branches,
      personTitle,
      middle_name,
      marital_status,
      nationality,
      location,
      emp_no,
      socNo,
      workingHrs,
      jobContract,
      insurance_no,
      is_sponsored_emp,
      city,
      country,
      postcode,
      kin_contact_no_2,
      kin_address,
      kin_city,
      kin_country,
      kin_email,
      kin_postcode,
      kin_relationship
    };

    editEmployeeData(data).then((response) => {
      if (response.data.status_code == 1) {
        Swal.fire("Success", "Successfully updated", "success");
      } else {
        Swal.fire("Warning", " " + response.data.message, "warning");
      }
    });
  };
  const handleSubmitBankData = (e) => {
    e.preventDefault();
    const bankData = {
      id: bankEditingId,
      is_primary_account: isPrimaryAccount ? 1 : 0,
      bankName,
      bankBranch,
      accountName,
      accountNo,
      sort_code: bankSortCode,
      emp_id: employee_id,
    };
    if (bankEditingId == '0') {
      addEmployeeBankData(bankData).then((response) => {
        if (response.data.status_code == 1) {
          BankFormClear();
          loadData();
          Swal.fire("Success", response.data.message, "success");
        } else {
          Swal.fire("Warning", " " + response.data.message, "warning");
        }
      });
    } else {
      editEmployeeBankData(bankData).then((response) => {
        if (response.data.status_code == 1) {
          BankFormClear();
          loadData();
          Swal.fire("Success", "Successfully updated", "success");
        } else {
          Swal.fire("Warning", " " + response.data.message, "warning");
        }
      });
    }

  };
  const changeTeam = (e) => {
    const value = e.target.value;
    setTeam(value);
  };

  const popupDocument = (url) => {
    let win = window.open(
      url,
      null,
      "popup,width=500,height=600,left=0,right=0"
    );
  };

  $('.digit_only').keypress(function (e) {

    var charCode = (e.which) ? e.which : event.keyCode

    if (String.fromCharCode(charCode).match(/[^0-9]/g))

      return false;
  });
  const NumericOnly = (e) => { //angka only
    const reg = /^[0-9\b]+$/
    let preval = e.target.value
    if (e.target.value === '' || reg.test(e.target.value)) return true
    else e.target.value = preval.substring(0, (preval.length - 1))
  }

  function onFocus() {
    // console.log("focus");
  }

  const onChangeLeaves = (value) => {
    setLeaveTypes(value);
  };

  const onChangeBranches = (value) => {
    setSelectedBranches(value);
  };


  const submitLeaveTypes = () => {
    let remove_empty_value = "";
    leave_types = leave_types.filter(function (item) {
      return item !== remove_empty_value
    })
    let emp_id = id;
    const LeaveData = { emp_id, leave_types };

    assignLeaveTypes(LeaveData).then((response) => {

      if (response.data.status_code == "1") {
        Swal.fire("Success", "" + response.data.message, "success");
      } else {
        Swal.fire("Warning", " " + response.data.message, "warning");
      }
    });

  }

  const getDocumentListLengthByType = (documents, documentType) => {
    const document = documents.find(doc => doc.document_type == documentType);
    return document ? document.employee_document_list.length : 0;
  };

  const submitDocuments = (documentList) => {
    if (documentType == 0 || documentType == "") {
      Swal.fire("Warning", "Please select document type", "warning");
    } else if (documentList.length == 0) {
      Swal.fire("Warning", "Please upload document", "warning");
    } else if((documentType == EMP_PASSPORT_TYPE_ID || documentType == EMP_VISA_TYPE_ID) && getDocumentListLengthByType(uploadedEmpDocuments, documentType) != 0){
      Swal.fire("Warning", "The document has been uploaded previously for this document type. You can't upload multiple documents.", "warning");
    }else if((documentType == EMP_PASSPORT_TYPE_ID || documentType == EMP_VISA_TYPE_ID) && getDocumentListLengthByType(uploadedEmpDocuments, documentType) == 0 && documentList.length > 1){
      Swal.fire("Warning", "Please clear the document and select only one for this document type upload.", "warning");
    }else {
      const formData = new FormData();
      documentList.forEach(file => {
        formData.append('files[]', file);
      });
      formData.append('document_type', documentType);
      formData.append('emp_id', id);

      uploadEmployeeDocument(formData).then((response) => {

        if (response.data.status_code == "1") {
          Swal.fire("Success", "" + response.data.message, "success");
          setDocumentType("");
          changeDropZoneList();
          loadData()
        } else {
          Swal.fire("Warning", " " + response.data.message, "warning");
        }
      });
    }

  }
  const changeDropZoneList = () => {
    setDocumentDataList([]);
  }
  const deleteDocument = (documentID) => {
    const delete_record = { documentId: documentID };
    deleteEmployeeDocument(delete_record).then((response) => {

      if (response.data.status_code == "1") {
        Swal.fire("Success", "" + response.data.message, "success");
        loadData()
      } else {
        Swal.fire("Warning", " " + response.data.message, "warning");
      }
    });
  }

  const Edit = (data) => {
    setBankEditingId(data.id);
    setAccountName(data.account_name);
    setBankName(data.bank_name);
    setBankBranch(data.branch);
    setAccountNo(data.account_no);
    setBankSortCode(data.sort_code)
    setIsPrimaryAccount(data.is_primary_account == 1 ? true : false)
  }

  const BankFormClear = () => {
    setBankEditingId('0');
    setAccountName('');
    setBankName('');
    setBankBranch('');
    setAccountNo('');
    setBankSortCode('');
    setIsPrimaryAccount(false);
  }

  const onChangeIsCorrect = (e) => {
    setIsPrimaryAccount(!isPrimaryAccount)
  }

  const onChangeCountry = (value) => {
    modalType == EMP_VISA_TYPE ? setVisaIssuedCountry(value.value) : setPassIssuedCountry(value.value)
  };

  const onChangeMainSectionCountry = (value,type) => {
    type == 'kin' ? setKinCountry(value.value) : setCountry(value.value);
  };


  // not  used - other section multiple upload
  const submitOtherDocuments = (documents) => {
    const formData = new FormData();
    documents.forEach(file => {
      formData.append('attachment[]', file);
    });

    otherMultipleDocumentUpload(formData).then((response) => {
      if (response.data.status_code == "1") {
        setAttachmentPath(response.data.data)
        Swal.fire({  
          title: "Success",
          text: response.data.message,
          icon: "success",
          showConfirmButton: false, 
          timer: 3000 });
      } else {
       
        Swal.fire("Warning", response.data.message, "warning");
      }
    });
  }

  const openDocViewModal = (type, display_name,data) =>{
    setOtherDocArray(data ? JSON.parse(data):[])
    setViewModalTitle('Attachment - '+ display_name);
    setDocVisible(true);
  }
  //
  const openModal = (type, method, data) => {
    setConfirmLoading(false);
    setModalMethod(method);
    setModalType(type);
    setModalTitle(method + " " + type);
    setModalDataId(data.id ?? "0");

    if (method == 'New') {
      otherDetailsClear();
    }
    if (type == EMP_EDUCATION_TYPE && method == 'Edit') {
      setEduDegree(data.degree);
      setEduInstitution(data.institution);
      setEduMajor(data.major);
      seteduYear(data.year);
      // setAttachmentPath(data.attachment);
      setAttachmentLabel("Replace");
    } else if (type == EMP_CERTIFICATION_TYPE && method == 'Edit') {
      setCertiInstitution(data.institution);
      setCertiName(data.name);
      setCertiIssueDate(data.issuedDate);
      setCertiDescription(data.description);
      setCertiExpDate(data.expDate);
      // setAttachmentPath(data.attachment);
      setAttachmentLabel("Replace");
    } else if (type == EMP_PASSPORT_TYPE && method == 'Edit') {
      setPassNumber(data.number);
      setPassIssuedCountry(data.issuedCountry);
      setPassCurrentActive(data.currentActive);
      setPassExpDate(data.expDate);
      setPassIssueDate(data.issuedDate);
      // setAttachmentPath(data.attachment);
      setAttachmentLabel("Replace");
    } else if (type == EMP_VISA_TYPE && method == 'Edit') {
      setVisaNumber(data.number);
      setVisaIssuedCountry(data.issuedCountry);
      setVisaCurrentActive(data.currentActive);
      setVisaExpDate(data.expDate);
      setVisaIssueDate(data.issuedDate);
      setVisaType(data.visaType);
      // setAttachmentPath(data.attachment);
      setAttachmentLabel("Replace");
    } else if (type == EMP_COS_TYPE && method == 'Edit') {
      setCosLicenceNo(data.licenceNo);
      setCosSponsorName(data.sponsorName);
      setCosCertiNo(data.certificateNo);
      setCosCertiDate(data.certificateDate);
      setCosAssignedDate(data.assignedDate);
      setCosExpDate(data.expDate);
      setCosSponsorNote(data.sponsorNote);
      setAttachmentPath(data.attachment);
      setCosAttachment('');
      setAttachmentLabel("Replace");
    } else if (type == EMP_RTW_TYPE && method == 'Edit') {
      setRtwRef(data.rtwRef);
      setRtwCeckDate(data.checkDate);
      setRtwStatus(data.status);
      setRtwExpDate(data.expDate);
      setAttachmentPath(data.attachment);
      setRtwAttachment('');
      setAttachmentLabel("Replace");
    }

    setVisible(true);

  };

  function otherDetailsClear() {
  
    $('.custom-file-label').html("Choose file");
    rtwAttachment != "" ? inputRef.current.value = "" : '',
    cosAttachment != "" ? inputRef2.current.value = "" : '',
    setAttachmentLabel("");
    setAttachmentPath("");
    setEduDegree('');
    setEduInstitution('');
    setEduMajor('');
    seteduYear('');
    setCertiInstitution('');
    setCertiName('');
    setCertiIssueDate('');
    setCertiDescription('');
    setCertiExpDate('');
    setPassNumber('');
    setPassIssuedCountry('');
    setPassCurrentActive('');
    setPassExpDate('');
    setPassIssueDate('');
    setVisaNumber('');
    setVisaIssuedCountry('');
    setVisaCurrentActive('');
    setVisaExpDate('');
    setVisaIssueDate('');
    setVisaType('');
    setCosLicenceNo('');
    setCosSponsorName('');
    setCosCertiNo('');
    setCosCertiDate('');
    setCosAssignedDate('');
    setCosExpDate('');
    setCosSponsorNote('');
    setCosAttachment('');
    setRtwRef('');
    setRtwCeckDate('');
    setRtwStatus('');
    setRtwExpDate('');
    setRtwAttachment('');
  }

  const updatedOtherDataset = (array, key, newValues) => {
    const updatedArrayData = array.map((item) => {
      if (item.id == key) {
        return newValues; // Replace the entire object
      }
      return item; // Return the original object if the id doesn't match
    });
    return updatedArrayData;
  };

  const handleImage = async (event) => {
    $('.custom-file-label').html(event.name);
    modalType == EMP_COS_TYPE ? setCosAttachment(event) : setRtwAttachment(event);
    handleAttachmentSubmit(event);
  };

  const handleAttachmentSubmit = async (file) => {
    const data = new FormData();
    data.append("document", file);
    const uploadSatatus = await otherDocumentUpload(data).then((response) => {
      if (response.data.status_code == "1") {
        setAttachmentPath(response.data.data);
        return true;
      } else {
        $('.custom-file-label').html("Choose file");
        setAttachmentPath("");
        setCosAttachment("");
        setRtwAttachment("");
        Swal.fire("Warning", "Attachment uploading failed. Try again later", "warning");
        return false;
      }
    });
    return uploadSatatus;
  }
  const handleModalSubmit = (e) => {
    let updatedArray = "{}";
    setConfirmLoading(true);
    let finalStatus = true;
    if (modalType == EMP_PASSPORT_TYPE) {
     if(passNumber == "" || passIssuedCountry == "" || passIssueDate == "" || passExpDate == "" || passCurrentActive == ""){
      Swal.fire("Warning", "Please fill the required details", "warning");
      finalStatus = false;
     }else{
      let newRow = { id: modalDataId == 0 ? passportList.length + 1 : modalDataId, number: passNumber, issuedCountry: passIssuedCountry, issuedDate: passIssueDate, expDate: passExpDate, currentActive: passCurrentActive };
      updatedArray = modalDataId == 0 ? [...passportList, newRow] : updatedOtherDataset(passportList, modalDataId, newRow);
    }
      
    } else if (modalType == EMP_EDUCATION_TYPE) {
      if(eduDegree == "" || eduInstitution == "" || eduYear == ""){
        Swal.fire("Warning", "Please fill the required details", "warning");
        finalStatus = false;
      }else{
        let newRow = { id: modalDataId == 0 ? educationList.length + 1 : modalDataId, degree: eduDegree, institution: eduInstitution, major: eduMajor, year: eduYear};
        updatedArray = modalDataId == 0 ? [...educationList, newRow] : updatedOtherDataset(educationList, modalDataId, newRow);
      }
     
    } else if (modalType == EMP_VISA_TYPE) {
      if(visaNumber == "" || visaIssuedCountry == "" || visaIssueDate == "" || visaExpDate == "" || visaCurrentActive == "" || visaType == ""){
        Swal.fire("Warning", "Please fill the required details", "warning");
        finalStatus = false;
      }else{
        let newRow = { id: modalDataId == 0 ? visaList.length + 1 : modalDataId, number: visaNumber, issuedCountry: visaIssuedCountry, issuedDate: visaIssueDate, expDate: visaExpDate, currentActive: visaCurrentActive, visaType: visaType};
        updatedArray = modalDataId == 0 ? [...visaList, newRow] : updatedOtherDataset(visaList, modalDataId, newRow);
      }
   
    } else if (modalType == EMP_CERTIFICATION_TYPE) {
      if(certiName == "" || certiInstitution == "" || certiDescription == "" ||certiIssueDate == ""){
        Swal.fire("Warning", "Please fill the required details", "warning");
        finalStatus = false;
      }else{
          let newRow = { id: modalDataId == 0 ? certificationList.length + 1 : modalDataId, name: certiName, institution: certiInstitution, description: certiDescription, issuedDate: certiIssueDate, expDate: certiExpDate };
          updatedArray = modalDataId == 0 ? [...certificationList, newRow] : updatedOtherDataset(certificationList, modalDataId, newRow);
      }
     
    } else if (modalType == EMP_COS_TYPE) {
     if(cosLicenceNo == "" || cosSponsorName == "" || cosCertiNo == "" || cosCertiDate == "" || cosAssignedDate == "" || cosExpDate == "") {
        Swal.fire("Warning", "Please fill the required details", "warning");
        finalStatus = false;
      }else{
        if (modalDataId == 0 && attachmentPath != "" || modalDataId != 0) {
          let newRow = { id: modalDataId == 0 ? COSList.length + 1 : modalDataId, licenceNo: cosLicenceNo, sponsorName: cosSponsorName, certificateNo: cosCertiNo, certificateDate: cosCertiDate, assignedDate: cosAssignedDate, expDate: cosExpDate, sponsorNote: cosSponsorNote, attachment: attachmentPath };
          updatedArray = modalDataId == 0 ? [...COSList, newRow] : updatedOtherDataset(COSList, modalDataId, newRow);
        } else{
          Swal.fire("Warning", "Please add attachment", "warning");
          finalStatus = false;
        }
      }

    } else if (modalType == EMP_RTW_TYPE) {
      if(rtwRef == "" || rtwCheckDate == "" || rtwExpDate == "" || rtwStatus == ""){
        Swal.fire("Warning", "Please fill the required details", "warning");
        finalStatus = false;
      }else {
        if (modalDataId == 0 && attachmentPath != "" || modalDataId != 0) {
          let newRow = { id: modalDataId == 0 ? rtwList.length + 1 : modalDataId, rtwRef: rtwRef, checkDate: rtwCheckDate, expDate: rtwExpDate, status: rtwStatus, attachment: attachmentPath };
          updatedArray = modalDataId == 0 ? [...rtwList, newRow] : updatedOtherDataset(rtwList, modalDataId, newRow);
        } else {
          Swal.fire("Warning", "Please add attachment", "warning");
          finalStatus = false;
        }
      }
    }

    if (finalStatus) {
      const dataSubmit = { emp_id: employee_id, user_id: user_id, detail_type: modalType, row_data: JSON.stringify(updatedArray) };
      storeOtherDetails(dataSubmit).then((response) => {
        if (response.data.status_code == 1) {
          otherDetailsClear();
          setVisible(false);
          loadData();
          Swal.fire("Success", response.data.message, "success");
        } else {
          Swal.fire("Warning", " " + response.data.message, "warning");
        }
        setConfirmLoading(false);
      });
    } else {
      setConfirmLoading(false);
    }

  }
  return (
    <>
      <div className="row">
        <div className="col-md-12 d-flex align-items-center">
          <BackButton/>
          <h3>
            Edit Employee - {first_name} {last_name}
          </h3>
        </div>
      </div>
      <section className="users-edit">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-content">
                <div className="card-body">
                  <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <a
                        href="#account"
                        role="tab"
                        id="account-tab"
                        className="nav-link d-flex align-items-center active"
                        data-toggle="tab"
                        aria-controls="account"
                        aria-selected="true"
                      >
                        <i className="fa fa-user mr-1"></i>
                        <span className="d-none d-sm-block">Information</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#information"
                        role="tab"
                        id="information-tab"
                        className="nav-link d-flex align-items-center"
                        data-toggle="tab"
                        aria-controls="information"
                        aria-selected="false"
                      >
                        <i className="fa fa-bank mr-1"></i>
                        <span className="d-none d-sm-block">Bank Details</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#other"
                        role="tab"
                        id="other-tab"
                        className="nav-link d-flex align-items-center"
                        data-toggle="tab"
                        aria-controls="other"
                        aria-selected="false"
                      >
                        <i class="fa fa-list mr-1"></i>
                        <span className="d-none d-sm-block">Edu & Travel Details</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#authorization"
                        role="tab"
                        id="authorization-tab"
                        className="nav-link d-flex align-items-center"
                        data-toggle="tab"
                        aria-controls="authorization"
                        aria-selected="false"
                      >
                        <i class="fa fa-drivers-license mr-1"></i>
                        <span className="d-none d-sm-block">Authorization</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#document"
                        role="tab"
                        id="document-tab"
                        className="nav-link d-flex align-items-center"
                        data-toggle="tab"
                        aria-controls="document"
                        aria-selected="false"
                      >
                        <i class="fa fa-folder mr-1"></i>
                        <span className="d-none d-sm-block">Documents</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#leave-types"
                        role="tab"
                        id="leave-types-tab"
                        className="nav-link d-flex align-items-center"
                        data-toggle="tab"
                        aria-controls="leave-types"
                        aria-selected="false"
                      >
                        <i class="fa fa-check-square mr-1"></i>
                        <span className="d-none d-sm-block">Leave Types</span>
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div
                      className="tab-pane fade mt-2 show active"
                      id="account"
                      role="tabpanel"
                      aria-labelledby="account-tab"
                    >
                      <form
                        onSubmit={(e) => {
                          handleSubmitMainData(e);
                        }}
                      >
                        <fieldset>
                          <h5 className="font-weight-bold mb-3"> <i class="fa fa-briefcase fa-fw"></i> Work Profile</h5>
                          <div className="form-row">
                            <div className="col-md-1 col-12">
                              <div className="form-group position-relative">
                                <label for="title">
                                  Title <span style={{ color: "red" }}>*</span>
                                </label>
                                <select
                                  id="personTitle"
                                  name="personTitle"
                                  className="form-control"
                                  style={{ padding: '0.375rem 0.3rem' }}
                                  value={personTitle}
                                  onChange={(e) => setPersonTitle(e.target.value)}
                                  required
                                >
                                  <option value="" selected disabled> </option>
                                  <option value="Mr">Mr</option>
                                  <option value="Ms">Ms</option>
                                  <option value="Mrs">Mrs</option>
                                  <option value="Miss">Miss</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-4 col-12">
                              <div className="form-group position-relative">
                                <label for="first_name">
                                  First Name{" "}<span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  id="first_name"
                                  className="form-control"
                                  placeholder="First Name"
                                  name="first_name"
                                  value={first_name}
                                  onChange={(e) => setFirstName(e.target.value)}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-3 col-12">
                              <div className="form-group position-relative">
                                <label for="middle_name">
                                  Middle Name
                                </label>
                                <input
                                  type="text"
                                  id="middle_name"
                                  className="form-control"
                                  placeholder="Middle Name"
                                  name="middle_name"
                                  value={middle_name}
                                  onChange={(e) => setMiddleName(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-4 col-12">
                              <div className="form-group position-relative">
                                <label for="last_name">
                                  Last Name{" "}<span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  id="last_name"
                                  className="form-control"
                                  placeholder="Last Name"
                                  name="last_name"
                                  value={last_name}
                                  onChange={(e) => setLastName(e.target.value)}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="col-md-3 col-12">
                              <div className="form-group position-relative">
                                <label for="emp_no">
                                  Employee No
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  id="emp_no"
                                  className="form-control"
                                  placeholder="Employee no"
                                  name="emp_no"
                                  value={emp_no}
                                  onChange={(e) => setEmpNo(e.target.value)}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-3 col-12">
                              <div className="form-group position-relative">
                                <label for="nic">
                                  NIC <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  id="nic"
                                  className="form-control"
                                  placeholder="NIC"
                                  name="nic"
                                  value={nic}
                                  onChange={(e) => setNic(e.target.value)}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-3 col-12">
                              <div className="form-group position-relative">
                                <label for="nic">
                                  SOC No
                                </label>
                                <input
                                  type="text"
                                  id="soc_no"
                                  className="form-control"
                                  placeholder="SOC no"
                                  name="soc_no"
                                  value={socNo}
                                  onChange={(e) => setSocNo(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-3 col-12">
                              <div className="form-group position-relative">
                                <label for="mobile_no">
                                  Mobile No{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <PhoneInput
                              international
                              // countryCallingCodeEditable={false}
                                placeholder="Mobile No"
                                style={{border: '0px !important'}}
                                value={mobile_no}
                                className="form-control"
                                onChange={setMobileNo} required/>
                              </div>
                            </div>

                          </div>
                          <div className="form-row">
                            <div className="col-md-3 col-12">
                              <div className="form-group position-relative">
                                <label for="workingHrs">
                                  Weekly Working hrs <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  id="workingHrs"
                                  className="form-control"
                                  placeholder="Working Hrs"
                                  name="workingHrs"
                                  value={workingHrs}
                                  onChange={(e) => setWorkingHrs(e.target.value)}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-3 col-12">
                              <div className="form-group position-relative">
                                <label for="form-action-3">
                                  Work location
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <select
                                  id="location"
                                  name="location"
                                  className="form-control"
                                  value={location}
                                  onChange={(e) => setLocation(e.target.value)}
                                  required
                                >
                                  <option value="" selected disabled>Select Location</option>
                                  <option value="Sri Lanka">Sri Lanka</option>
                                  <option value="India">India</option>
                                  <option value="UK">UK</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6 col-12">
                              <label className="form-label">
                                Assigned Branches <span style={{ color: "red" }}>*</span>
                              </label>
                              <Select
                                mode="multiple"
                                value={selected_branches}
                                onFocus={onFocus}
                                allowClear
                                style={{
                                  width: "100%",
                                }}
                                placeholder="Please select"
                                onChange={onChangeBranches}
                                filterOption={(input, option) =>
                                  option.children
                                    .toString()
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                required
                              >
                                {branchesList.map((item, i) => {
                                  return (
                                    <Option value={item.id}>
                                      {item.name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="col-md-4 col-12">
                              <div className="form-group position-relative">
                                <label for="form-action-3">
                                  Employment Status{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <select
                                  id="emp_type"
                                  name="emp_type"
                                  className="form-control"
                                  value={emp_type}
                                  onChange={(e) => setEmpType(e.target.value)}
                                  required
                                >
                                  <option value="" disabled>
                                    Select employee status
                                  </option>
                                  {empTypeList.map((item, i) => {
                                    return (
                                      <option value={item.id}>
                                        {item.employment_type}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>

                            <div className="col-md-4 col-12">
                              <div className="form-group position-relative">
                                <label for="form-action-3">
                                  Designation{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <select
                                  id="designation"
                                  name="designation"
                                  className="form-control"
                                  value={designation}
                                  onChange={(e) => setDesignation(e.target.value)}
                                  required
                                >
                                  <option value="" disabled>
                                    Select designation
                                  </option>
                                  {designationList.map((item, i) => {
                                    return (
                                      <option value={item.id}>
                                        {item.designation_name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                            <div className="col-md-4 col-12">
                              <div className="form-group position-relative">
                                <label for="form-action-4">
                                  Team <span style={{ color: "red" }}>*</span>
                                </label>
                                <select
                                  id="team"
                                  name="team"
                                  className="form-control"
                                  value={team}
                                  onChange={changeTeam}
                                  required
                                >
                                  <option value="" disabled>
                                    Select team
                                  </option>
                                  {teamList.map((item, i) => {
                                    return (
                                      <option value={item.id}>
                                        {item.team_name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>

                          </div>
                          <div className="form-row">
                            <div className="col-md-4 col-12">
                              <div className="form-group position-relative">
                                <label for="form-action-3">
                                  Leader / Coordinator
                                  <span style={{ color: "red" }}> *</span>
                                </label>
                                <select
                                  id="leadership"
                                  name="leadership"
                                  className="form-control"
                                  value={leader_or_coordinator}
                                  onChange={(e) => setLeadership(e.target.value)}
                                  required
                                >
                                  <option value="No">No</option>
                                  <option value="Leader">Leader</option>
                                  <option value="Coordinator">Coordinator</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-4 col-12">
                              <div className="form-group position-relative">
                                <label for="form-action-3">
                                  Reporting Manager
                                </label>
                                <input
                                  type="text"
                                  id="reportingManager"
                                  className="form-control"
                                  name="reportingManager"
                                  value={reportingManager}
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="col-md-4 col-12">
                              <div className="form-group position-relative">
                                <label for="form-action-4">
                                  Joined Date{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <DatePicker
                                  disabledDate={(current) =>
                                    current.isAfter(moment())
                                  }
                                  dateFormat="yyyy-MM-dd"
                                  selected={moment(joined_date)}
                                  value={moment(joined_date)}
                                  onChange={(date) => setJoinedDate(date)}
                                  className="form-control"
                                  placeholder="Joined Date"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="col-md-4 col-12">
                              <div className="form-group position-relative">
                                <label for="jobContract">
                                  Job Contract
                                </label>
                                <input
                                  type="text"
                                  id="jobContract"
                                  className="form-control"
                                  placeholder="Job Contract"
                                  name="jobContract"
                                  value={jobContract}
                                  onChange={(e) => setJobContract(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-4 col-12">
                              <div className="form-group position-relative">
                                <label for="insurance_no">
                                  national insurance no <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  id="insurance_no"
                                  className="form-control"
                                  placeholder="Insurance no"
                                  name="insurance_no"
                                  value={insurance_no}
                                  onChange={(e) => setInsuranceNo(e.target.value)}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-4 col-12">
                              <div className="form-group position-relative">
                                <label for="form-action-3">
                                  sponsored employee
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <select
                                  id="is_sponsored_emp"
                                  name="is_sponsored_emp"
                                  className="form-control"
                                  value={is_sponsored_emp}
                                  onChange={(e) => setIsSponsoredEmp(e.target.value)}
                                  required
                                >
                                  <option value="0">No</option>
                                  <option value="1">Yes</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                        <fieldset>
                          <hr />
                          <h5 className="font-weight-bold mb-3"><i class="fa fa-user"></i> Personal Info</h5>
                          <div className="form-row">
                            <div className="col-md-3 col-12">
                              <div className="form-group position-relative">
                                <label for="dob">Date of Birth</label>
                                <DatePicker
                                  className="form-control"
                                  placeholder="Date of Birth"
                                  format="YYYY-MM-DD"
                                  selected={dob != "" && dob != null ? moment(dob) : ""}
                                  value={dob != "" && dob != null ? moment(dob) : ""}
                                  onChange={(dob) => setDob(dob)}
                                  // maxDate={subYears(new Date(), 18)}
                                  disabledDate={(current) => {
                                    return (
                                      moment().subtract(18, "years") <= current
                                    );
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-3 col-12">
                              <div className="form-group position-relative">
                                <label for="home_tp_no">
                                  Home TP Number
                                </label>
                                <PhoneInput
                              international
                              // countryCallingCodeEditable={false}
                                placeholder="Home TP Number"
                                style={{border: '0px !important'}}
                                value={home_tp_no}
                                className="form-control"
                                onChange={setHTNo} />
                              </div>
                            </div>
                            <div className="col-md-6 col-12">
                              <div className="form-group position-relative">
                                <label for="personal_email">
                                  Personal Email <span style={{ color: "red" }}> *</span>
                                </label>
                                <input
                                  type="email"
                                  id="personal_email"
                                  className="form-control"
                                  placeholder="Personal Email"
                                  name="personal_email"
                                  value={personal_email}
                                  onChange={(e) =>
                                    setPersonalEmail(e.target.value)
                                  }
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="col-md-3 col-12">
                              <label className="form-label">
                                Gender <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                className="form-control"
                                value={gender}
                                required
                                onChange={(e) => setGender(e.target.value)}
                              >
                                <option value="" selected disabled> select option</option>
                                <option value="1"> Male</option>
                                <option value="0"> Female</option>
                              </select>
                            </div>
                            <div className="col-md-3 col-12">
                              <label className="form-label">
                                marital status <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                className="form-control"
                                value={marital_status}
                                required
                                onChange={(e) => setMaritalStatus(e.target.value)}
                              >
                                <option value="" selected disabled> select option</option>
                                <option value="Single"> Single</option>
                                <option value="Married"> Married</option>
                                <option value="Divorced"> Divorced</option>
                                <option value="Separated"> Separated</option>
                              </select>
                            </div>
                            <div className="col-md-6 col-12">
                              <div className="form-group position-relative">
                                <label for="nationality">
                                  nationality{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  id="nationality"
                                  className="form-control"
                                  placeholder="Nationality"
                                  name="nationality"
                                  value={nationality}
                                  onChange={(e) =>
                                    setNationality(e.target.value)
                                  }
                                  required
                                />
                              </div>
                            </div>
                          </div>

                          <div className="form-row">
                            <div className="col-md-6 col-12">
                              <div className="form-group position-relative">
                                <label for="form-action-3">
                                  Permanent Address{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  id="form-action-4"
                                  className="form-control pickadate"
                                  name="date-closed"
                                  placeholder="Permanent Address"
                                  value={permanent_address}
                                  onChange={(e) =>
                                    setPermanentAddress(e.target.value)
                                  }
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-6 col-12">
                              <div className="form-group position-relative">
                                <label for="form-action-3">
                                  Temporary Address
                                </label>
                                <input
                                  type="text"
                                  id="form-action-4"
                                  className="form-control pickadate"
                                  name="date-closed"
                                  placeholder="Temporary Address"
                                  value={temporary_address}
                                  onChange={(e) =>
                                    setTemporaryAddress(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="col-md-4 col-12">
                              <div className="form-group position-relative">
                                <label for="city">
                                  City <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  id="personal_city"
                                  className="form-control"
                                  placeholder="City"
                                  name="personal_city"
                                  value={city}
                                  onChange={(e) =>
                                    setCity(e.target.value)
                                  }
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-4 col-12">
                              <div className="form-group position-relative">
                                <label for="personal_postcode">
                                  Post code <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  id="personal_city"
                                  className="form-control"
                                  placeholder="Postcode"
                                  name="personal_postcode"
                                  value={postcode}
                                  onChange={(e) =>
                                    setPostCode(e.target.value)
                                  }
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-4 col-12">
                              <div className="form-group position-relative">
                                <label for="city">
                                  Country <span style={{ color: "red" }}>*</span>
                                </label>
                                <Select
                                labelInValue={true}
                                showSearch
                                className="form-control"
                                placeholder="Search to Select"
                                placement={"bottomLeft"}
                                optionFilterProp="value"
                                value={country}
                                onChange={(e) => onChangeMainSectionCountry(e,'personal')}
                                filterSort={(optionA, optionB) =>
                                  (optionA?.value ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={Countries}
                                required
                              />
                               
                              </div>
                            </div>
                          </div>
                        </fieldset>

                        <fieldset>
                          <hr />
                          <h5 className="font-weight-bold mb-3"><i class="fa fa-phone"></i> Emergency Contact</h5>
                          <div className="form-row">
                            <div className="col-md-6 col-12">
                              <div className="form-group position-relative">
                                <label for="form-action-3">
                                  kin name <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  id="kin_name"
                                  className="form-control"
                                  placeholder="Kin name"
                                  value={kin_name}
                                  onChange={(e) => setKinName(e.target.value)}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-6 col-12">
                              <div className="form-group position-relative">
                                <label for="kin_relationship">
                                  relationship <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  id="kin_relationship"
                                  className="form-control"
                                  placeholder="Relationship"
                                  value={kin_relationship}
                                  onChange={(e) => setKinRelationship(e.target.value)}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-3 col-12">
                              <div className="form-group position-relative">
                                <label for="form-action-3">
                                  contact no (Primary) <span style={{ color: "red" }}>*</span>
                                </label>
                                <PhoneInput
                              international
                              // countryCallingCodeEditable={false}
                                placeholder="Kin contact no"
                                style={{border: '0px !important'}}
                                value={kin_contact_no}
                                className="form-control"
                                onChange={setKinContactNo} required />
                              </div>
                            </div>
                            <div className="col-md-3 col-12">
                              <div className="form-group position-relative">
                                <label for="form-action-3">
                                  contact no (secondary)
                                </label>
                                <PhoneInput
                              international
                              // countryCallingCodeEditable={false}
                                placeholder="2nd contact no"
                                // style={{border: '0px !important'}}
                                value={kin_contact_no_2}
                                className="form-control"
                                onChange={setKinContactNo2} />
                                
                              </div>
                            </div>
                            <div className="col-md-6 col-12">
                              <div className="form-group position-relative">
                                <label for="form-action-3">
                                  email
                                </label>
                                <input
                                  type="email"
                                  id="kin_email"
                                  className="form-control"
                                  name="kin_email"
                                  placeholder="Kin email"
                                  value={kin_email}
                                  onChange={(e) => setKinEmail(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-12 col-12">
                              <div className="form-group position-relative">
                                <label for="form-action-3">
                                  Address <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  id="kin_address"
                                  className="form-control"
                                  name="kin_address"
                                  placeholder="Kin address"
                                  value={kin_address}
                                  onChange={(e) => setKinAddress(e.target.value)}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-4 col-12">
                              <div className="form-group position-relative">
                                <label for="form-action-3">
                                  City <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  id="kin_city"
                                  className="form-control"
                                  name="kin_city"
                                  placeholder="City"
                                  value={kin_city}
                                  onChange={(e) => setKinCity(e.target.value)}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-4 col-12">
                              <div className="form-group position-relative">
                                <label for="form-action-3">
                                  Postcode <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  id="kin_postcode"
                                  className="form-control"
                                  name="kin_postcode"
                                  placeholder="Postcode"
                                  value={kin_postcode}
                                  onChange={(e) => setKinPostcode(e.target.value)}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-4 col-12">
                              <div className="form-group position-relative">
                                <label for="form-action-3">
                                  Country <span style={{ color: "red" }}>*</span>
                                </label>
                                <Select
                                labelInValue={true}
                                showSearch
                                className="form-control"
                                placeholder="Search to Select"
                                placement={"bottomLeft"}
                                optionFilterProp="value"
                                value={kin_country}
                                onChange={(e) => onChangeMainSectionCountry(e,'kin')}
                                filterSort={(optionA, optionB) =>
                                  (optionA?.value ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={Countries}
                              />
                              </div>
                            </div>
                          </div>
                        </fieldset>
                        <div className="form-row">
                          <div className="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                            <button
                              type="submit"
                              className="btn btn-primary mb-2 mb-sm-0"
                            >
                              Save Changes
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>

                    <div
                      className="tab-pane fade mt-2"
                      id="information"
                      role="tabpanel"
                      aria-labelledby="information-tab"
                    >
                      <form
                        onSubmit={(e) => {
                          handleSubmitBankData(e);
                        }}
                      >
                        <div className="form-row">
                          <div className="col-md-4 col-12">
                            <div className="form-group">
                              <label for="users-edit-twitter">
                                Bank Name:{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={bankName}
                                onChange={(e) => setBankName(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-12">
                            <div className="form-group">
                              <label for="users-edit-facebook">
                                Branch <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={bankBranch}
                                onChange={(e) => setBankBranch(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-12">
                            <div className="form-group">
                              <label for="users-edit-twitter">
                                Sort Code
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={bankSortCode}
                                onChange={(e) => setBankSortCode(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-6 col-12">
                            <div className="form-group">
                              <label for="users-edit-linkedin">
                                Account Name{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={accountName}
                                onChange={(e) => setAccountName(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-12">
                            <div className="form-group">
                              <label for="users-edit-google">
                                Account No{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control digit_only"
                                value={accountNo}
                                onChange={(e) => setAccountNo(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-2 col-12">
                            <div className="form-group">
                              <label>Primary Account</label>
                              <Switch checkedChildren="Yes" unCheckedChildren="No" onChange={(e) => onChangeIsCorrect(e)} checked={isPrimaryAccount} />
                            </div>
                          </div>

                        </div>
                        <div className="form-row">
                          <div className="col-12 d-flex justify-content-end flex-sm-row flex-column mt-3 mt-sm-0">
                            <button
                              type="submit"
                              className="btn btn-primary mb-2 mb-sm-0"
                            >
                              {bankEditingId == '0' ? 'Add New' : 'Save Changes'}
                            </button>
                          </div>
                        </div>
                      </form>
                      <div className="row mt-2">
                        <div className="col-md-12 col-12">
                          <div className="table-responsive">
                            <div className="table">
                              <Table columns={columns} dataSource={bankList} loading={banksLoading} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade mt-2"
                      id="other"
                      role="tabpanel"
                      aria-labelledby="other-tab"
                    >
                      <div className="card">
                        <div className="d-flex justify-content-between"><h5><i class="fa fa-address-card-o mr-1"></i>{EMP_PASSPORT_TYPE}</h5>
                          <button
                            type="button"
                            className="btn btn-sm btn-primary"
                            onClick={(e) => openModal(EMP_PASSPORT_TYPE, 'New', "")}
                          >
                            Add New <i class="ft-plus"></i>
                          </button></div>
                        <EmployeeOtherTable dataset={passportList} type={EMP_PASSPORT_TYPE} columnsSet={PassportColumns} tableLoading={banksLoading} openModal={openModal} docViewModal={openDocViewModal}/>
                      </div>

                      <div className="card">
                        <div className="d-flex justify-content-between"><h5><i class="fa fa-vcard mr-1"></i>{EMP_VISA_TYPE}</h5>
                          <button
                            type="button"
                            className="btn btn-sm btn-primary"
                            onClick={(e) => openModal(EMP_VISA_TYPE, 'New', "")}
                          >
                            Add New <i class="ft-plus"></i>
                          </button></div>
                        <EmployeeOtherTable dataset={visaList} type={EMP_VISA_TYPE} columnsSet={VisaColumns} tableLoading={banksLoading} openModal={openModal} docViewModal={openDocViewModal} />
                      </div>

                      <div className="card">
                        <div className="d-flex justify-content-between"><h5><i class="fa fa-graduation-cap mr-1"></i>{EMP_EDUCATION_TYPE}</h5>
                          <button
                            type="button"
                            className="btn btn-sm btn-primary"
                            onClick={(e) => openModal(EMP_EDUCATION_TYPE, 'New', "")}
                          >
                            Add New <i class="ft-plus"></i>
                          </button></div>
                        <EmployeeOtherTable dataset={educationList} type={EMP_EDUCATION_TYPE} columnsSet={EducationColumns} tableLoading={banksLoading} openModal={openModal} docViewModal={openDocViewModal}/>
                      </div>

                      <div className="card">
                        <div className="d-flex justify-content-between"><h5><i class="fa fa-certificate mr-1"></i>{EMP_CERTIFICATION_TYPE}</h5>
                          <button
                            type="button"
                            className="btn btn-sm btn-primary"
                            onClick={(e) => openModal(EMP_CERTIFICATION_TYPE, 'New', "")}
                          >
                            Add New <i class="ft-plus"></i>
                          </button>
                        </div>
                        <EmployeeOtherTable dataset={certificationList} type={EMP_CERTIFICATION_TYPE} columnsSet={CertificationColumns} tableLoading={banksLoading} openModal={openModal} docViewModal={openDocViewModal}/>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade mt-2"
                      id="authorization"
                      role="tabpanel"
                      aria-labelledby="authorization-tab"
                    >
                      <div className="card">
                        <div className="d-flex justify-content-between"><h5><i class="fa fa-id-badge mr-1"></i>{EMP_COS_TYPE}</h5>
                          <button
                            type="button"
                            className="btn btn-sm btn-primary"
                            onClick={(e) => openModal(EMP_COS_TYPE, 'New', "")}
                          >
                            Add New <i class="ft-plus"></i>
                          </button></div>
                        <EmployeeOtherTable dataset={COSList} type={EMP_COS_TYPE} columnsSet={COSColumns} tableLoading={banksLoading} openModal={openModal} />
                      </div>
                      <div className="card">
                        <div className="d-flex justify-content-between"><h5><i class="fa fa-vcard-o mr-1"></i>Right To Work</h5>
                          <button
                            type="button"
                            className="btn btn-sm btn-primary"
                            onClick={(e) => openModal(EMP_RTW_TYPE, 'New', "")}
                          >
                            Add New <i class="ft-plus"></i>
                          </button></div>
                        <EmployeeOtherTable dataset={rtwList} type={EMP_RTW_TYPE} columnsSet={RTWColumns} tableLoading={banksLoading} openModal={openModal} />
                      </div>
                    </div>

                    <div
                      className="tab-pane fade mt-2"
                      id="document"
                      role="tabpanel"
                      aria-labelledby="document-tab"
                    >
                      <fieldset>
                        <form id="form-document">
                          <div className="row">
                            <div className="col-12 col-md-4">
                              <div className="form-group">
                                <label className="form-label">
                                  Document Type{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <select
                                  className="form-control"
                                  name="documentType"
                                  id="documentType"
                                  value={documentType}
                                  required
                                  onChange={(e) =>
                                    setDocumentType(e.target.value)
                                  }
                                >
                                  <option value="" disabled>
                                    {" "}
                                    Select document type
                                  </option>
                                  {documentTypeList.map((item, i) => {
                                    return (
                                      <option value={item.id}>  {item.name}</option>
                                    );
                                  })}


                                </select>
                              </div>
                            </div>

                            <div className="col-12 col-md-8">
                              {/* <Dropzone onDrop={this.onDrop}></Dropzone> */}
                              <FileUpload submitDocuments={submitDocuments} changeDropZoneList={documentDataList} />
                            </div>
                          </div>
                        </form>
                      </fieldset>
                      <fieldset>
                        <br />
                        <div className="row">
                          <div className="col-12 col-md-12">
                            <h5>Uploaded Documents</h5>
                          </div>

                          {uploadedEmpDocuments.map((document, i) => {
                            let documentsList = [];
                            return (
                              <div className="col-12 col-md-4 mb-2">
                                <label for="basic-form-8">
                                  <b> {document.name} </b>
                                  <small> - {document.approval_status.replace("_", " ")}</small>
                                </label>
                                {document.employee_document_list.length !== 0 ? (

                                  <>
                                    <EmployeeDocumentCard docArray={document.employee_document_list} page={'edit-employee'} deleteDocument={deleteDocument}></EmployeeDocumentCard>
                                  </>

                                ) : (
                                  <p>No PDF file specified.</p>
                                )}
                              </div>
                            );
                          })}

                        </div>
                      </fieldset>
                    </div>
                    <div className="tab-pane fade mt-2" id="leave-types" role="tabpanel" aria-labelledby="leave-types-tab">
                      <div className="form-row">
                        <div className="col-md-10 col-12">
                          <label className="form-label">
                            Assign Leave Type <span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            mode="multiple"
                            value={leave_types}
                            onFocus={onFocus}
                            allowClear
                            style={{
                              width: "100%",
                            }}
                            placeholder="Please select"
                            onChange={onChangeLeaves}
                            filterOption={(input, option) =>
                              option.children
                                .toString()
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            required
                          >
                            {leave_type_list.map((item, i) => {
                              return (
                                <Option value={item.id}>
                                  {item.leave_type}
                                </Option>
                              );
                            })}
                          </Select>

                        </div>
                        <div className="col-md-1 col-12">
                          <label className="form-label">

                          </label>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={submitLeaveTypes}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        title={modalTitle}
        visible={visible}
        okText="Save"
        confirmLoading={confirmLoading}
        onCancel={() => setVisible(false)}
        onOk={(e) => {
          handleModalSubmit(e);
        }}>
        <div className="row">
          {modalType == EMP_EDUCATION_TYPE ? (<>
            <div className="col-md-12 col-12">
              <div className="form-group">
                <label>
                  Degree:{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={eduDegree}
                  onChange={(e) => setEduDegree(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-12 col-12">
              <div className="form-group">
                <label>
                  Institution:{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={eduInstitution}
                  onChange={(e) => setEduInstitution(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label>
                  Major:
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={eduMajor}
                  onChange={(e) => setEduMajor(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label>
                  Year:{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <YearPicker className="form-control"
                  disabledDate={(current) =>
                    current.isAfter(moment())
                  }
                  format="YYYY"
                  value={eduYear != "" ? momentTZ(eduYear, 'YYYY') : ""} picker="year" onChange={(year) => seteduYear(momentTZ(year).tz('Asia/Colombo').year())} mode="year" />
              </div>
            </div>
            {/* <div className="col-md-12 col-12">
            <div className="form-group">
            <label>{attachmentLabel} Attachment {attachmentLabel == "" ? <span style={{ color: "red" }}>*</span>:''}</label>
            <FileUpload submitDocuments={submitOtherDocuments} changeDropZoneList={documentDataList} buttonDisabled={true}/>
            </div>
            </div> */}
          </>) : modalType == EMP_CERTIFICATION_TYPE ? (<>
            <div className="col-md-12 col-12">
              <div className="form-group">
                <label>
                  Institution:{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={certiInstitution}
                  onChange={(e) => setCertiInstitution(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-12 col-12">
              <div className="form-group">
                <label>
                  Name:{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={certiName}
                  onChange={(e) => setCertiName(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-12 col-12">
              <div className="form-group">
                <label>
                  Description:{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={certiDescription}
                  onChange={(e) => setCertiDescription(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label>
                  Issued Date:{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <DatePicker
                  disabledDate={(current) =>
                    current.isAfter(moment())
                  }
                  dateFormat="yyyy-MM-dd"
                  placement={"bottomLeft"}
                  allowClear={false}
                  selected={moment(certiIssueDate)}
                  value={certiIssueDate != "" ? moment(certiIssueDate) : ""}
                  onChange={(date) => setCertiIssueDate(date)}
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label>
                  Exp Date:{" "}
                </label>
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  placement={"bottomLeft"}
                  allowClear={false}
                  selected={moment(certiExpDate)}
                  value={certiExpDate != "" ? moment(certiExpDate) : ""}
                  onChange={(date) => setCertiExpDate(date)}
                  className="form-control"
                />
              </div>
            </div>
            {/* <div className="col-md-12 col-12">
            <div className="form-group">
            <label>{attachmentLabel} Attachment {attachmentLabel == "" ? <span style={{ color: "red" }}>*</span>:''}</label>
            <FileUpload submitDocuments={submitOtherDocuments} changeDropZoneList={documentDataList} buttonDisabled={true}/>
            </div>
            </div> */}
          </>) :
            modalType == EMP_PASSPORT_TYPE ? (<>
              <div className="col-md-6 col-12">
                <div className="form-group">
                  <label>
                    Number:{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={passNumber}
                    onChange={(e) => setPassNumber(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="form-group">
                  <label>
                    Country of issue:{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <Select
                    labelInValue={true}
                    showSearch
                    className="form-control"
                    placeholder="Search to Select"
                    placement={"bottomLeft"}
                    optionFilterProp="value"
                    value={passIssuedCountry}
                    onChange={onChangeCountry}
                    filterSort={(optionA, optionB) =>
                      (optionA?.value ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={Countries}
                  />

                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="form-group">
                  <label>
                    Issued date:{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <DatePicker
                    disabledDate={(current) =>
                      current.isAfter(moment())
                    }
                    dateFormat="yyyy-MM-dd"
                    placement={"bottomLeft"}
                    allowClear={false}
                    selected={moment(passIssueDate)}
                    value={passIssueDate != "" ? moment(passIssueDate) : ""}
                    onChange={(date) => setPassIssueDate(date)}
                    className="form-control"
                  />

                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="form-group">
                  <label>
                    Exp date:{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    placement={"bottomLeft"}
                    allowClear={false}
                    selected={moment(passExpDate)}
                    value={passExpDate != "" ? moment(passExpDate) : ""}
                    onChange={(date) => setPassExpDate(date)}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="form-group">
                  <label>
                    Current active:{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="form-control"
                    value={passCurrentActive}
                    onChange={(e) => setPassCurrentActive(e.target.value)}
                    required
                  >
                    <option value="" selected disabled>Select option</option>
                    <option value="no">No</option>
                    <option value="yes">Yes</option>
                  </select>
                </div>
              </div>
              {/* <div className="col-md-12 col-12">
              <div className="form-group">
              <label>{attachmentLabel} Attachment {attachmentLabel == "" ? <span style={{ color: "red" }}>*</span>:''}</label>
              <FileUpload submitDocuments={submitOtherDocuments} changeDropZoneList={documentDataList} buttonDisabled={true}/>
              </div>
            </div> */}
            </>) :
              modalType == EMP_VISA_TYPE ? (<>
                <div className="col-md-6 col-12">
                  <div className="form-group">
                    <label>
                      Number:{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={visaNumber}
                      onChange={(e) => setVisaNumber(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="form-group">
                    <label>
                      Country of issue:{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <Select
                      labelInValue={true}
                      showSearch
                      className="form-control"
                      placeholder="Search to Select"
                      placement={"bottomLeft"}
                      optionFilterProp="value"
                      value={visaIssuedCountry}
                      onChange={onChangeCountry}
                      filterSort={(optionA, optionB) =>
                        (optionA?.value ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={Countries}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="form-group">
                    <label>
                      Issued date:{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <DatePicker
                      disabledDate={(current) =>
                        current.isAfter(moment())
                      }
                      dateFormat="yyyy-MM-dd"
                      allowClear={false}
                      selected={moment(visaIssueDate)}
                      value={visaIssueDate != "" ? moment(visaIssueDate) : ""}
                      onChange={(date) => setVisaIssueDate(date)}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="form-group">
                    <label>
                      Exp date:{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      allowClear={false}
                      selected={moment(visaExpDate)}
                      value={visaExpDate != "" ? moment(visaExpDate) : ""}
                      onChange={(date) => setVisaExpDate(date)}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="form-group">
                    <label>
                      Visa Type:{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-control"
                      value={visaType}
                      onChange={(e) => setVisaType(e.target.value)}
                      required
                    >
                      <option value="" selected disabled>Select Type </option>
                      <option value="Tourist Visa">Tourist Visa</option>
                      <option value="Study Visa">Study Visa</option>
                      <option value="Work Visa">Work Visa</option>
                      <option value="Transit Visa">Transit Visa</option>
                      <option value="PR Visa">PR Visa</option>
                      <option value="Medical Visa">Medical Visa</option>
                      <option value="Business Visa">Business Visa</option>
                      <option value="Pilgrim Visa">Pilgrim Visa</option>
                      <option value="Official Visa">Official Visa</option>
                      <option value="Pensioner Visa">Pensioner Visa</option>
                      <option value="Investor Visa">Investor Visa</option>
                    </select>

                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="form-group">
                    <label>
                      Current active:{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-control"
                      value={visaCurrentActive}
                      onChange={(e) => setVisaCurrentActive(e.target.value)}
                      required
                    >
                      <option value="" selected disabled>Select option</option>
                      <option value="no">No</option>
                      <option value="yes">Yes</option>
                    </select>

                  </div>
                </div>
                {/* <div className="col-md-12 col-12">
                <div className="form-group">
                <label>{attachmentLabel} Attachment {attachmentLabel == "" ? <span style={{ color: "red" }}>*</span>:''}</label>
                <FileUpload submitDocuments={submitOtherDocuments} changeDropZoneList={documentDataList} buttonDisabled={true}/>
                </div>
                </div> */}
              </>) :

                modalType == EMP_COS_TYPE ? (<>
                  <div className="col-md-4 col-12">
                    <div className="form-group">
                      <label>
                        Licence no:{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={cosLicenceNo}
                        onChange={(e) => setCosLicenceNo(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-8 col-12">
                    <div className="form-group">
                      <label>
                        Sponsor Name:{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={cosSponsorName}
                        onChange={(e) => setCosSponsorName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="form-group">
                      <label>
                        Certification No:{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={cosCertiNo}
                        onChange={(e) => setCosCertiNo(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="form-group">
                      <label>
                        Certification date:{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <DatePicker
                        disabledDate={(current) =>
                          current.isAfter(moment())
                        }
                        dateFormat="yyyy-MM-dd"
                        placement={"bottomLeft"}
                        allowClear={false}
                        selected={moment(cosCertiDate)}
                        value={cosCertiDate != "" ? moment(cosCertiDate) : ""}
                        onChange={(date) => setCosCertiDate(date)}
                        className="form-control"
                      />

                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="form-group">
                      <label>
                        Assigned date:{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <DatePicker
                        disabledDate={(current) =>
                          current.isAfter(moment())
                        }
                        dateFormat="yyyy-MM-dd"
                        placement={"bottomLeft"}
                        allowClear={false}
                        selected={moment(cosAssignedDate)}
                        value={cosAssignedDate != "" ? moment(cosAssignedDate) : ""}
                        onChange={(date) => setCosAssignedDate(date)}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="form-group">
                      <label>
                        Exp date:{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <DatePicker
                        dateFormat="yyyy-MM-dd"
                        placement={"bottomLeft"}
                        allowClear={false}
                        selected={moment(cosExpDate)}
                        value={cosExpDate != "" ? moment(cosExpDate) : ""}
                        onChange={(date) => setCosExpDate(date)}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="form-group">
                      <label>
                        Attachment:{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>

                      <div className="form-group mt-2">
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="compose-email-attach"
                            accept="application/pdf"
                            ref={inputRef2}
                            onChange={(e) => handleImage(inputRef2.current.files[0])}
                          />
                          <label className="custom-file-label" for="compose-email-attach">
                            Attach file
                          </label>
                          <p class="text-muted mb-0 mt-1 mt-sm-0">
                            <small>*Allowed only PDF.</small>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="form-group">
                      <label>
                        Sponsor Note:{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={cosSponsorNote}
                        onChange={(e) => setCosSponsorNote(e.target.value)}
                      />
                    </div>
                  </div>
                </>) : modalType == EMP_RTW_TYPE ? (<>
                  <div className="col-md-6 col-12">
                    <div className="form-group">
                      <label>
                        RTW Ref:{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={rtwRef}
                        onChange={(e) => setRtwRef(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 col-12">
                    <div className="form-group">
                      <label>
                        Date of check:{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <DatePicker
                        disabledDate={(current) =>
                          current.isAfter(moment())
                        }
                        dateFormat="yyyy-MM-dd"
                        placement={"bottomLeft"}
                        allowClear={false}
                        selected={moment(rtwCheckDate)}
                        value={rtwCheckDate != "" ? moment(rtwCheckDate) : ""}
                        onChange={(date) => setRtwCeckDate(date)}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="form-group">
                      <label>
                        Exp date:{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <DatePicker
                        dateFormat="yyyy-MM-dd"
                        placement={"bottomLeft"}
                        allowClear={false}
                        selected={moment(rtwExpDate)}
                        value={rtwExpDate != "" ? moment(rtwExpDate) : ""}
                        onChange={(date) => setRtwExpDate(date)}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="form-group">
                      <label>
                        Status:{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        className="form-control"
                        value={rtwStatus}
                        onChange={(e) => setRtwStatus(e.target.value)}
                        required
                      >
                        <option value="" selected disabled>Select option</option>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="form-group">
                      <label>
                        Attachment:{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>

                      <div className="form-group mt-2">
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="compose-email-attach"
                            accept="application/pdf"
                            ref={inputRef}
                            onChange={(e) => handleImage(inputRef.current.files[0])}
                          />
                          <label className="custom-file-label" for="compose-email-attach">
                            Attach file
                          </label>
                          <p class="text-muted mb-0 mt-1 mt-sm-0">
                            <small>*Allowed only PDF.</small>
                          </p>
                        </div>
                      </div>

                    </div>
                  </div>
                </>) : (<></>)}
        </div>
      </Modal>
      <Modal
       title={viewModalTitle}
       visible={docVisible}
       onCancel={() => setDocVisible(false)}
       onOk={() => {setDocVisible(false)}}
      >
        <EmployeeOtherDocumentCard docArray={otherDocArray}/>
      </Modal>
    </>
  );
}
export default EditEmployee;
