import React, { useState, useEffect } from "react";

import Swal from "sweetalert2";
import { Select, TimePicker, Table, Modal } from "antd";
import { getDropdownList, getNotifySetting, submitDropdownOption, submitSettingDetails, updateDropdownOption } from "../../services/ComplianceAPIServices";
import "antd/dist/antd.css";
import moment from "moment";
import { getLoggedUserDetails } from "../../services/AuthAPIServices";

function AgeVerification({ id }) {

  const { Option } = Select;
  const [notifyFrom, setNotifyFrom] = useState("");
  const [notifyTo, setNotifyTo] = useState("");
  const [intervals, setIntervals] = useState("");

  const [company_id, setCompanyId] = useState("");
  const [methodList, setMethodList] = useState([]);
  const [reasonList, setReasonList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [option_value, setOptionValue] = useState("");
  const [optionId, setOptionId] = useState("0");
  const [type, setType] = useState("1");
  const [method_id, setMethodId] = useState("3");
  const [reason_id, setReasonId] = useState("1");
  const [modalTitle, setModalTitle] = useState("");
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const user_id = localStorage.getItem("hms_user_id");
  const timeFormat = 'HH:mm';

  const AgeVerifyMethodsColumns = [ 
    {
        key: "option_value",
        title: "Method",
        dataIndex: "option_value",
    },
    {
        key: "action",
        title: "",
        render: (record) => {
          return (
            <>
            {record.option_value == "Others" ? (<></>):(<>
                <button
                className="badge badge-pill bg-light-info ml-2"
                style={{ border: '2px solid #dadada' }}
                onClick={() => Edit(record)}
              >
                <i className="ft-edit"></i>
              </button>
            </>)}
            
            </>
          );
        },
      },
];
const AgeVerifyReasonsColumns = [
    {
        key: "option_value",
        title: "Reason",
        dataIndex: "option_value",
    }, 
    {
        key: "action",
        title: "",
        render: (record) => {
          return (
            <>
                <button
                className="badge badge-pill bg-light-info ml-2"
                style={{ border: '2px solid #dadada' }}
                onClick={() => Edit(record)}
              >
                <i className="ft-edit"></i>
              </button>
         
            
            </>
          );
        },
    }
];

  useEffect(() => {
    loadSettingsData();
  }, [company_id]);


  const loadSettingsData = () => {

    const user = {
      user_id,
    };
    getLoggedUserDetails(user).then((response) => {
      setCompanyId(response.data.company.id);
    });

    getNotifySetting(id).then((response) => {
      let notifyData = JSON.parse(response.data.data.notify_settings);
  

      let result = Object.entries(notifyData).map(([k, v]) => ({ [k]: v }));
      result.forEach((item) => {
        var key = Object.keys(item)[0];
        setIntervals(item[key].intervals);
        var timeArray = [];
        timeArray.push(moment(item[key].from, "HH:mm").format('HH:mm'), moment(item[key].to, "HH:mm").format('HH:mm'));
        timeOnChange(item[key].from, timeArray)

      });
    }).catch((error) => { console.error(error) });

    setLoading(true);
    getDropdownList(company_id, method_id).then((response) => {
      setMethodList(response);
      setLoading(false);
    }).catch((error) => { console.error(error) });

    getDropdownList(company_id, reason_id).then((response) => {
      setReasonList(response);
      setLoading(false);
    }).catch((error) => { console.error(error) });
    
  }


  const timeOnChange = (dates, dateStrings) => {

    if (dates) {
      setNotifyFrom(dateStrings[0]);
      setNotifyTo(dateStrings[1]);
    } else {
      setNotifyFrom("");
      setNotifyTo("");
    }
  };

  function handleChange(value) {
    setIntervals(value);
  }

  const submitSettings = (e) => {

    if (notifyTo == "" || notifyFrom == "") {
      Swal.fire("Warning", "Please select time period.", "warning");
    } else if (intervals == "") {
      Swal.fire("Warning", "Please select notify time interval.", "warning");
    } else {

      var json_string = '{"0":{"intervals":"' + intervals + '","from":"' + notifyFrom + '","to":"' + notifyTo + '"}}';

      const data_json = { compliance_type: id, notify_settings: json_string }
      submitSettingDetails(data_json).then((response) => {
        if (response.data.status_code == 1) {
          Swal.fire("Success", "" + response.data.message, "success").then(function () {
            loadSettingsData();
          });
        } else {
          Swal.fire("Warning", " " + response.data.message, "warning");
        }
      }).catch((error) => {
        Swal.fire("Warning", "" + error.response.data.message, "warning");
      });

    }

  }

  const Edit = (data) => {
    setConfirmLoading(false);
    setOptionId(data.id);
    setType(data.category_id);
    setOptionValue(data.option_value);
    setModalTitle("Edit");
    setVisible(true);
  };

  const handleAddNewModal = () => {
    setConfirmLoading(false);
    setType("1")
    setOptionId("0");
    setOptionValue("");
    setModalTitle("Add New");
    setVisible(true);
  };

  const handleSubmit = (e) => {
    const data = { category_id:type, company_id, option_value,id:optionId };
    setConfirmLoading(true);
    
    if(optionId == '0'){
      submitDropdownOption(data).then((response) => {
        if (response.data.status_code == 1) {
          Swal.fire("Success", "" + response.data.message, "success").then(function () {
            setConfirmLoading(false);
            setVisible(false);
            loadSettingsData();
          });
        } else {
          setConfirmLoading(false);
          Swal.fire("Warning", " " + response.data.message, "warning");
        }
      }).catch((error) => {
        setConfirmLoading(false);
        Swal.fire("Warning", "" + error.message, "warning");
      });

    }else{

      updateDropdownOption(data).then((response) => {
        if (response.data.status_code == 1) {
          Swal.fire("Success", "" + response.data.message, "success").then(function () {
            setConfirmLoading(false);
            setVisible(false);
            loadSettingsData();
          });
        } else {
          setConfirmLoading(false);
          Swal.fire("Warning", " " + response.data.message, "warning");
        }
      }).catch((error) => {
        setConfirmLoading(false);
        Swal.fire("Warning", "" + error.message, "warning");
      });
    }

  }

  return (
    <>
      <section id="simple-table">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-content">
                <div className="card-body">
                  <div className="row">
                  <div className="col-md-12"><h5>Notification Settings</h5></div>
                    <div className="col-md-6">

                      <div className="col-md-12 p-1 d-flex justify-content-between align-items-center" >
                        <div className="col-md-5"><h6>Time period:</h6> </div>
                        <div className="col-md-7">
                          <small className="text-muted">{notifyFrom} - {notifyTo}</small>
                          <TimePicker.RangePicker use24Hours format={timeFormat} style={{ width: '100%' }} onChange={(e, x) => timeOnChange(e, x)} /></div>
                      </div>
                      <div className="col-md-12 p-1 d-flex justify-content-between align-items-center">
                        <div className="col-md-5"><h6>Intervals:</h6> </div>
                        <div className="col-md-7">
                          <Select className="form-control" value={intervals} style={{ width: '100%' }} onChange={handleChange}>
                            <Option value="1h">1 hour</Option>
                            <Option value="2h">2 hours</Option>
                            <Option value="3h">3 hours</Option>
                            <Option value="4h">4 hours</Option>
                            <Option value="5h">5 hours</Option>
                            <Option value="6h">6 hours</Option>
                          </Select>
                        </div>
                      </div>


                      <div className="col-md-12 p-1 d-flex justify-content-end align-items-center">
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary btn-sm float-right"
                            onClick={(e) => submitSettings(e)}
                          >
                            <i class="fa fa-check"></i> Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-content">
                <div className="card-body">
                  <div className="row">
                  <div className="col-md-12  float-right">
                    <button
                      type="button"
                      className="btn btn-primary mb-1  float-right"
                      onClick={(e) => handleAddNewModal(e)}
                    >
                      <i class="ft-plus"></i>New
                    </button>
                  </div>
                  <div className="col-md-6">
                    <div className="col-md-12"><h5>Methods</h5></div>
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <div className="table">
                          <Table
                            loading={loading}
                            dataSource={methodList}
                            columns={AgeVerifyMethodsColumns}
                            pagination={{
                              pageSize: 10,
                              position: ["bottomCenter"],
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    </div>
                  <div className="col-md-6">
                  <div className="col-md-12"><h5>Reasons</h5></div>
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <div className="table">
                          <Table
                            loading={loading}
                            dataSource={reasonList}
                            columns={AgeVerifyReasonsColumns}
                            pagination={{
                              pageSize: 10,
                              position: ["bottomCenter"],
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </section>
      <Modal
        title={modalTitle}
        visible={visible}
        okText="Save"
        confirmLoading={confirmLoading}
        onCancel={() => setVisible(false)}
        onOk={(e) => {
          handleSubmit(e);
        }}
      >
        <form>
          <div className="row">
            <div className="col-md-12">
              <label className="form-label">
                Select Option Type <span style={{ color: "red" }}>*</span>
              </label>
              <select
                className="form-control"
                value={type}
                required
                onChange={(e) => setType(e.target.value)}
              >
                <option value="" disabled>
                  Select Type
                </option>
                <option value={method_id}>Verification Method</option>
                <option value={reason_id}>Verification Reason</option>
              </select>
            </div>

            <div className="col-md-12">
              <label className="form-label">
                Option Name <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={option_value}
                onChange={(e) => setOptionValue(e.target.value)}
                required
              />
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
}
export default AgeVerification;
