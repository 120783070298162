import { Table } from "antd";
import { EMP_COS_TYPE, EMP_EDUCATION_TYPE, EMP_PASSPORT_TYPE, EMP_RTW_TYPE, EMP_VISA_TYPE } from "../AppConstant";

function EmployeeOtherTable({ dataset, type, tableLoading, columnsSet, openModal, docViewModal }) {

  const newColumn = {
    key: "action",
    title: "",
    width: '6%',
    render: (record) => {
      return (
        <>
          <div className="d-flex">
            {/* {type == EMP_RTW_TYPE || type == EMP_COS_TYPE ? (<>
            </>) : (
              <>
                <button className="badge bg-light-info action-btn mr-1" onClick={() => docViewModal(type, type == EMP_PASSPORT_TYPE || type == EMP_VISA_TYPE ? record.number :
                  type == EMP_EDUCATION_TYPE ? record.degree : record.institution, record.attachment)}><i class="fa fa-image"></i></button></>)} */}
            <button
              className="badge badge-pill bg-light-danger action-btn"
              style={{ color: "black" }}
              onClick={() => openModal(type, 'Edit', record)}
            >
              <i class="ft-edit"></i>
            </button>
          </div>
        </>
      );
    },
  }
  const columns = [...columnsSet, newColumn]
  return (
    <div className="row mt-2">
      <div className="col-md-12 col-12">
        <div className="table-responsive">
          <div className="table">
            <Table columns={columns} dataSource={dataset}
              pagination={{
                pageSize: 5
              }}
              loading={tableLoading} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployeeOtherTable;
