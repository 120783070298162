import axios from "axios";
import { APIEndpoint,token } from '../UrlPaths';


export async function requestAccountDelete(data) {
  try {
    const response = await axios.post(
     APIEndpoint+'/deleteRequest', data,
      { headers: { "Content-Type": "application/json"} });

    return response.data;
  } catch (error) {
   
    return error;
  }
}

export async function createNewUser(data) {
  try {
    const response = await axios.post(
     APIEndpoint+'/createUser', data,
      { headers: { "Content-Type": "application/json",Authorization: `Bearer ${token}`} });

    return response.data;
  } catch (error) {
   
    return error;
  }
}

export async function getUserList() {
  try {
    const response = await axios.get(
     APIEndpoint+'/getUserList',
      { headers: { "Content-Type": "application/json",Authorization: `Bearer ${token}`} });

    return response.data;
  } catch (error) {
   
    return error;
  }
}

export async function statusChangeUser(userId,data) {
  try {
    const response = await axios.post(
      `${APIEndpoint}/statusChangeUser/${userId}`, data,
      { headers: { "Content-Type": "application/json",Authorization: `Bearer ${token}`} });

    return response.data;
  } catch (error) {
   
    return error;
  }
}

export async function updateUser(data) {
  try {
    const response = await axios.post(
     APIEndpoint+'/updateUser', data,
      { headers: { "Content-Type": "application/json",Authorization: `Bearer ${token}`} });

    return response.data;
  } catch (error) {
   
    return error;
  }
}
