import React, { useState, useEffect } from "react";

import Swal from "sweetalert2";
import { Table, Input, Modal } from "antd";
import { getDropdownList, submitDropdownOption, updateDropdownOption } from "../../services/ComplianceAPIServices";
import "antd/dist/antd.css";
import { getLoggedUserDetails } from "../../services/AuthAPIServices";

function HealthAndSafetyIncidentSetting({ id }) {

  const [company_id, setCompanyId] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [option_value, setOptionValue] = useState("");
  const [optionId, setOptionId] = useState("0");
  const [category_id, setCategoryId] = useState("4");
  const [modalTitle, setModalTitle] = useState("Add New Location");
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const user_id = localStorage.getItem("hms_user_id");

  useEffect(() => {
    loadSettingsData();
  }, [company_id]);

  const loadSettingsData = () => {
  
    const user = {
      user_id,
    };
    getLoggedUserDetails(user).then((response) => {
      setCompanyId(response.data.company.id);
    });
    setLoading(true);
    getDropdownList(company_id, category_id).then((response) => {
      setCategoryList(response);
      setLoading(false);
    }).catch((error) => { console.error(error) });
  
  }

  const columns = [
    {
      key: "option_value",
      title: "Location",
      dataIndex: "option_value",
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return (
          record.option_value.toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      key: "action",
      title: "",
      render: (record) => {
        return (
          <>
            <button
              className="badge badge-pill bg-light-info ml-2"
              style={{ border: '2px solid #dadada' }}
              onClick={() => Edit(record)}
            >
              <i className="ft-edit"></i>
            </button>
          </>
        );
      },
    },
  ];

  const Edit = (data) => {
    setConfirmLoading(false);
    setOptionId(data.id);
    setOptionValue(data.option_value);
    setModalTitle("Edit Location");
    setVisible(true);
  };
  const handleNewCategoryModal = () => {
    setConfirmLoading(false);
    setOptionId("0");
    setOptionValue("");
    setModalTitle("Add New Location");
    setVisible(true);
  };

  const handleSubmit = (e) => {
    const data = { category_id, company_id, option_value,id:optionId };
    setConfirmLoading(true);
    
    if(optionId == '0'){
      submitDropdownOption(data).then((response) => {
        if (response.data.status_code == 1) {
          Swal.fire("Success", "" + response.data.message, "success").then(function () {
            setConfirmLoading(false);
            setVisible(false);
            loadSettingsData();
          });
        } else {
          setConfirmLoading(false);
          Swal.fire("Warning", " " + response.data.message, "warning");
        }
      }).catch((error) => {
        setConfirmLoading(false);
        Swal.fire("Warning", "" + error.message, "warning");
      });

    }else{

      updateDropdownOption(data).then((response) => {
        if (response.data.status_code == 1) {
          Swal.fire("Success", "" + response.data.message, "success").then(function () {
            setConfirmLoading(false);
            setVisible(false);
            loadSettingsData();
          });
        } else {
          setConfirmLoading(false);
          Swal.fire("Warning", " " + response.data.message, "warning");
        }
      }).catch((error) => {
        setConfirmLoading(false);
        Swal.fire("Warning", "" + error.message, "warning");
      });
    }

  }

  return (
    <>
      <section id="simple-table">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-content">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-7"><h5>Locations List</h5></div>
                    <div className="col-md-5 d-flex">
                      <Input.Search
                      placeholder="Search..."
                      style={{ marginBottom: 5, marginRight:10 }}
                      value={searchedText}
                      onSearch={(value) => {
                        setSearchedText(value);
                      }}
                      onChange={(e) => {
                        setSearchedText(e.target.value);
                      }}
                    />  <button
                      type="button"
                      className="btn btn-primary mb-1  float-right"
                      onClick={(e) => handleNewCategoryModal(e)}
                    >
                      <i class="ft-plus"></i>New
                    </button></div>
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <div className="table">
                          <Table
                            loading={loading}
                            dataSource={categoryList}
                            columns={columns}
                            pagination={{
                              pageSize: 10,
                              position: ["bottomCenter"],
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        title={modalTitle}
        visible={visible}
        okText="Save"
        confirmLoading={confirmLoading}
        onCancel={() => setVisible(false)}
        onOk={(e) => {
          handleSubmit(e);
        }}
      >
        <form>
          <div className="row">
            <div className="col-md-12">
              <label className="form-label">
                Name <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={option_value}
                onChange={(e) => setOptionValue(e.target.value)}
                required
              />

            </div>
          </div>
        </form>
      </Modal>
    </>
  );
}
export default HealthAndSafetyIncidentSetting;
