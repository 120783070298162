function AccessDenied() {
  return (
    <>
      {" "}
      <section id="error" class="auth-height">
        <div class="container-fluid">
          <div class="row full-height-vh">
            <div class="col-12 d-flex align-items-center justify-content-center">
              <div class="row">
                <div class="col-12 text-center">
                  <img
                    src="../../../app-assets/img/gallery/error.png"
                    alt=""
                    class="img-fluid error-img mt-2"
                    height="300"
                    width="400"
                  />
                  <h1 class="mt-4">401 - Access Denied!</h1>
                  <div class="w-75 error-text mx-auto mt-4">
                    <p>Sorry You don't have permission to this page.</p>
                  </div>
                  {/* <a href="dashboard1.html" class="btn btn-warning my-2">Back To Home</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default AccessDenied;
