import axios from "axios";
import { APIEndpoint,token } from '../UrlPaths';



export async function getAllSalarySlips(data) {
  try {
    const response = await axios.post(
      APIEndpoint+'/getEmpSalarySlip',data,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {
    console.log(error);
    return [];
  }
}


export async function uploadSlips(data) {
  try {
    const response = await axios.post(
      APIEndpoint+'/uploadPayslip',data,
      { headers: { 'Authorization': `Bearer ${token}`,'Content-Type': 'multipart/form-data'} }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function mailSendingAPI(data) {
  try {
    const response = await axios.post(
      APIEndpoint+'/payslipSending',data,
      { headers: { 'Authorization': `Bearer ${token}`} }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function payslipDelete(data) {
  try {
    const response = await axios.post(
      APIEndpoint+'/payslipDelete',data,
      { headers: { 'Authorization': `Bearer ${token}`} }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
}