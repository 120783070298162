export const APIEndpoint = "https://apiv2.hrm.archnix.dev/api";
export const displayUrl = "https://apiv2.hrm.archnix.dev/assets/profile_imgs";
export const attachmentUrl = "https://apiv2.hrm.archnix.dev/assets/messageAttachment";
export const documentUrl = "https://apiv2.hrm.archnix.dev/assets/documents";
export const paySilpUrl = "https://apiv2.hrm.archnix.dev/assets/payslips";
export const empDocumentUrl = "https://apiv2.hrm.archnix.dev/assets/employee_documents";
export const adminUrl = "https://apiv2.hrm.archnix.dev/";
export const websiteUrl = "https://apiv2.hrm.archnix.dev/";
export const token = localStorage.getItem("hms_access_token");

// export const APIEndpoint = "http://127.0.0.1:8005/api";
// export const displayUrl = "http://127.0.0.1:8005/assets/profile_imgs";
// export const attachmentUrl = "http://127.0.0.1:8005/assets/messageAttachment";
// export const documentUrl = "http://127.0.0.1:8005/assets/documents";
// export const paySilpUrl = "http://127.0.0.1:8005/assets/payslips";
// export const empDocumentUrl = "http://127.0.0.1:8005/assets/employee_documents";
// export const adminUrl = "http://127.0.0.1:8005/";
// export const websiteUrl = "http://127.0.0.1:3001/";
// export const token = localStorage.getItem("hms_access_token");