import { adminUrl, empDocumentUrl, websiteUrl } from "../UrlPaths";
import Slider from "react-slick";
import { Document, pdfjs } from 'react-pdf'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, marginRight: "16px" }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, marginLeft: "15px", zIndex: "100" }}
            onClick={onClick}
        />
    );
}

function EmployeeOtherDocumentCard({ docArray }) {

    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    var settings = {
        // dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };



    const popupDocument = (url) => {
        let win = window.open(
            url,
            null,
            "popup,width=500,height=600,left=0,right=0"
        );
    };

    function getExtension(filename) {
        return filename.split('.').pop()
    }

    return (<>
        {docArray.length > 0 ?
            <Slider {...settings}>
                {docArray.map((list, i) => {
                    let exe = getExtension(list)
                    return (
                        <> <div className="mb-1">
                            <div className="d-flex justify-content-between p-1">
                                <span className="float-left">Doc: {i + 1} / {docArray.length}</span>
                            </div>
                            {exe.toLowerCase() == 'pdf' ? (<>
                                <div className="d-flex justify-content-center align-items-center" style={{ width: '100%', height: '220px', textAlign: 'center' }}>
                                    <div className="iframe-container" onClick={() => popupDocument(`${adminUrl}${list}`)} style={{ cursor: 'pointer' }}>
                                        <img src={`${websiteUrl}app-assets/img/pdf-format.png`} alt="image" style={{ width: '22%', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} className="img-fluid" />
                                        <small className="text-muted">{list.split('/').pop()}</small>

                                    </div>
                                </div>
                            </>) : (<>
                                <div>
                                    <div onClick={() => popupDocument(`${adminUrl}${list}`)} style={{ cursor: 'pointer' }}>
                                        <img src={`${adminUrl}${list}`} alt="image" className="img-fluid" />
                                    </div>
                                </div>
                            </>)}
                        </div>
                        </>);
                })}
            </Slider>
            : (<><span>No uploaded attachments</span></>)}
    </>);
}

export default EmployeeOtherDocumentCard;
