import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { getSpecificEmployeeData } from "../../services/EmployeeAPIServices";
import { displayUrl } from '../../UrlPaths';
import BackButton from "../../components/BackButton";

function Profile() {
  const { id } = useParams();

  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [designation, setDesignation] = useState("");
  const [team, setTeam] = useState("");
  const [nic, setNic] = useState("");
  const [emp_type, setEmpType] = useState("");
  const [joined_date, setJoinedDate] = useState("");
  const [personal_email, setPersonalEmail] = useState("");
  const [mobile_no, setMobileNo] = useState("");
  const [home_tp_no, setHTNo] = useState("");
  const [permanent_address, setPermanentAddress] = useState("");
  const [location, setLocation] = useState("");
  const [kin_name, setKinName] = useState("");
  const [kin_contact_no, setKinContactNo] = useState("");
  const [email, setOfsEmail] = useState("");
  const [img_path, setImagePath] = useState("");
  const [leader_or_coordinator, setLeadership] = useState("");
  const [temporary_address, setTemporaryAddress] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankBranch, setBankBranch] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [accountName, setAccountName] = useState("");
  const [notes, setNotes] = useState("");
  const spinner = document.getElementById("spinner");
  
  useEffect(() => {

    getSpecificEmployeeData(id).then((res) => {
      // console.log(res.first_name);
      setFirstName(res.mainData.first_name);
      setLastName(res.mainData.last_name);
      setDob(res.mainData.dob);
      setDesignation(res.mainData.designation_name);
      setTeam(res.mainData.team_name);
      setNic(res.mainData.nic);
      setEmpType(res.mainData.employment_type);
      setJoinedDate(res.mainData.joined_date);
      setPersonalEmail(res.mainData.personal_email);
      setMobileNo(res.mainData.mobile_no);
      setHTNo(res.mainData.home_tp_no);
      setPermanentAddress(res.mainData.permanent_address);
      setOfsEmail(res.mainData.email);
      setKinName(res.mainData.kin_name);
      setKinContactNo(res.mainData.kin_contact_no);
      setImagePath(res.mainData.profile_img_path);
      setLeadership(res.mainData.leader_or_coordinator);
      setTemporaryAddress(res.mainData.temporary_address);
      setLeadership(res.mainData.leader_or_coordinator)
      setAccountName(res.mainData.account_name);
      setBankName(res.mainData.bank_name);
      setBankBranch(res.mainData.branch);
      setAccountNo(res.mainData.account_no);
      setNotes(res.mainData.notes);
      spinner.style.display = "none";
    });

  }, []);

  return (
    <>
      <section className="users-view">
        <div className="row">
        <div className="col-md-12 d-flex align-items-center">
        <BackButton/></div>
          <div className="col-8 col-sm-7">
            <div className="media d-flex align-items-center">
              <a href="javascript:;">
                <img
                  src={`${displayUrl}/${img_path}`}
                  alt="user view avatar"
                  className="users-avatar-shadow rounded"
                  height="64"
                  width="64"
                />
              </a>
              <div className="media-body ml-3">
                <h4>
                  <span className="users-view-name">{first_name} {last_name}</span>
                </h4>
                <span>ID:</span>
                <span className="users-view-id">{id}</span>
              </div>
            </div>
          </div>
          <div className="col-4 col-sm-5 d-flex justify-content-end align-items-center">
            <a
              href={`mailto:${email}`}
              className="btn btn-sm bg-light-secondary mr-2 px-3 py-1"
            >
              <i className="ft-mail"></i>
            </a>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-content">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-xl-6">
                      <table className="table table-borderless">
                        <tbody>
                          <tr>
                            <td>Joined:</td>
                            <td>{joined_date}</td>
                          </tr>
                          <tr>
                            <td>Designation:</td>
                            <td className="users-view-role">{designation}</td>
                          </tr>
                          <tr>
                            <td>Employment:</td>
                            <td className="users-view-verified">{emp_type}</td>
                          </tr>
                          <tr>
                              <td>Team:</td>
                              <td className="users-view-username">{team}</td>
                            </tr>
                         
                        </tbody>
                      </table>
                    </div>
                    <div className="col-12 col-xl-6 users-module">
                      <div className="table-responsive">
                        <table className="table table-borderless">
                          <tbody>
                            <tr>
                              <td>Mobile no:</td>
                              <td className="users-view-name">{mobile_no}</td>
                            </tr>
                            <tr>
                              <td>E-mail:</td>
                              <td>{email}</td>
                            </tr>
                           
                            <tr>
                              <td>Leadership:</td>
                              <td>{leader_or_coordinator}</td>
                            </tr>
                            <tr>
                            <td>Status:</td>
                            <td>
                              <span className="badge bg-light-success users-view-status">
                                Active
                              </span>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="card">
              <div className="card-content">
                <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <h5 className="mb-2 text-bold-500">
                      <i className="ft-info mr-2"></i> Personal Info
                    </h5>
                    </div>
                    <div className="col-12 col-xl-6">
                    <div className="table-responsive">
                      <table className="table table-borderless m-0 users-module">
                        <tbody>
                        <tr>
                            <td>Permanent Address:</td>
                            <td>{permanent_address}</td>
                          </tr>
                          <tr>
                            <td>Temporary Address:</td>
                            <td>{temporary_address}</td>
                          </tr>
                          <tr>
                            <td>Home TP No:</td>
                            <td>{home_tp_no}</td>
                          </tr>
                          <tr>
                          <td>Birthday:</td>
                            <td>{dob}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    </div>
                    <div className="col-12 col-xl-6 users-module">
                    <div className="table-responsive">
                        <table className="table table-borderless">
                          <tbody>
                            <tr>
                            <td>Personal email:</td>
                            <td>{personal_email}</td>
                            </tr>
                            <tr>
                            <td>NIC:</td>
                            <td>{nic}</td>
                            </tr>
                           
                            <tr>
                            <td>Kin Name:</td>
                            <td>{kin_name}</td>
                            </tr>
                            <tr>
                            <td>Kin contact no:</td>
                            <td>{kin_contact_no}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-12">
                    <h5 className="mb-2 text-bold-500">
                      <i className="ft-link mr-2"></i>Bank Details
                    </h5>
                    <div className="table-responsive">
                      <table className="table table-borderless">
                        <tbody>
                          <tr>
                            <td>Bank Name:</td>
                            <td>{bankName}</td>
                          </tr>
                          <tr>
                            <td>Branch:</td>
                            <td>{bankBranch}</td>
                          </tr>
                          <tr>
                            <td>Account No:</td>
                            <td>{accountNo}</td>
                          </tr>
                          <tr>
                            <td>Account Name:</td>
                            <td>{accountName}</td>
                          </tr>
                        </tbody>
                      </table>
                      </div>
                      <h6>Notes :</h6><p> {notes}</p>
                    </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Profile;
