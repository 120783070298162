import React, { useState, useEffect } from "react";
import { Input, Table, Button, DatePicker, Select, Modal, Form, InputNumber, Popconfirm, Typography } from "antd";
import "antd/dist/antd.css";
import { editAttendanceTimeLog, getWeekTimeLogData, getWeekWiseTimeSheet } from "../services/AttendanceAPIServices";
import { SearchOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv";
import moment from "moment";
import Swal from "sweetalert2";
import dayjs from 'dayjs';
import { getActiveEmployees } from "../services/EmployeeAPIServices";

function HourlyAttendance({ editAccess }) {

  const { RangePicker } = DatePicker;

  const spinner = document.getElementById("spinner");
  const [loading, setLoading] = useState(false);
  const [columnsData, setColumnsData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [monthName, setMonthName] = useState("");
  const [from, setFilterFrom] = useState(moment().startOf("month").format('YYYY-MM-DD'));
  const [to, setFilterTo] = useState(moment().format('YYYY-MM-DD'));
  const [empList, setEmpList] = useState([]);
  const [emp_id, setEmpID] = useState('0');
  const [editEmpID, setEditEmpID] = useState('0');
  const [editEmpName, setEditEmpName] = useState("");
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [viewModalTitle, setViewModalTitle] = useState("");
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editModalTitle, setEditModalTitle] = useState("");
  const [weekWiseDataset, setWeekWiseDataset] = useState([]);
  const [weekEditDataset, setweekEditDataset] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [form] = Form.useForm();

  const mainColumns = [
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      filterMode: "tree",
      filterSearch: true,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type employee name"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropDown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.name.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: monthName,
      children: columnsData.map((attendance, indexCount) => {
        const { year, week_number, week_start_date, week_end_date } = attendance;
        return {
          title: <span>Week: {week_number} <br /><small>{moment(week_start_date).format("DD/MM/YYYY")} - {moment(week_end_date).format("DD/MM/YYYY")}</small></span>,
          dataIndex: "weekly_data",
          key: indexCount,
          align: "center",
          render: (values) =>
            values.map((value, index) => {
              let ret;
              if (index === indexCount) ret = values[index].total_hours;
              return (
                <>
                  {ret}
                </>
              );
            }),
        };
      }),
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: 80,
      render: (record) => <button
        className="badge badge-pill bg-light-success action-btn"
        onClick={() => viewMainData(record)}
      >
        <i className="fa fa-eye" aria-hidden="true"></i>
      </button>,
    },
  ];

  const weeklyDataColumns = [
    {
      key: "week_number",
      title: "Week No",
      render: (record) => <span>{record.year} - {record.week_number}</span>
    },
    {
      key: "week_start_date",
      title: "From",
      dataIndex: "week_start_date",
      render: (record) => moment(record).format("DD/MM/YYYY")
    },
    {
      key: "week_end_date",
      title: "To",
      dataIndex: "week_end_date",
      render: (record) => moment(record).format("DD/MM/YYYY")
    },
    {
      key: "total_hours",
      title: "Total Hours",
      dataIndex: "total_hours",
    },
    {
      title: 'View',
      key: 'operation',
      fixed: 'right',
      width: 80,
      render: (record) => <button
        className="badge badge-pill bg-light-info action-btn"
        onClick={() => editableDataView(record)}
      >
        <i className="fa fa-eye" aria-hidden="true"></i>
      </button>,
    },
  ];

  const weekDataColumns = [
    {
      key: "date",
      title: "Date",
      dataIndex: "date",
    },
    {
      key: "hours",
      title: "Hours",
      dataIndex: "hours",
      editable: true,
    },
    {
      key: "remarks",
      title: "Remarks",
      dataIndex: "remarks",
      editable: true,
    },
    {
      title: '',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => saveLog(record.id)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (<>
          {editAccess ? (<>
            <Typography.Link disabled={editingKey !== ''} onClick={() => editLog(record)}>
              Edit
            </Typography.Link>
          </>) : (<></>)}

        </>);
      },
    },
  ];

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const mergedColumns = weekDataColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'hours' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });


  const isEditing = (record) => record.id === editingKey;
  const editLog = (record) => {
    form.setFieldsValue({
      hours: '',
      remarks: '',
      ...record,
    });
    setEditingKey(record.id);
  };

  /*****  editing function ***********/
  const cancel = () => {
    setEditingKey('');
  };
  const saveLog = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...weekEditDataset];
      const index = newData.findIndex((item) => key === item.id);


      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setweekEditDataset(newData);

        editAttendanceTimeLog(newData[index]).then((response) => {
          if (response.status_code == 1) {
            Swal.fire("Success", " " + response.message, "success");
          } else {
            Swal.fire("Warning", " " + response.message, "warning");
          }
        });
        setEditingKey('');

      } else {
        newData.push(row);
        setweekEditDataset(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  useEffect(() => {

    setMonthName(moment(from).format("DD/MM/YYYY") + " - " + moment(to).format("DD/MM/YYYY"));
    const first_data = {
      from,
      to,
      emp_id
    };

    setLoading(true);
    getWeekWiseTimeSheet(first_data).then((response) => {
      if (response.length > 0) {
        setColumnsData(response[0].weekly_data);
        setRowData(response);
      } else {
        setColumnsData(response);
        setRowData(response);
      }
      spinner.style.display = "none";
      setLoading(false);
    });

    getActiveEmployees().then((res) => {
      let newRow = { 'value': '0', 'label': 'All Employee' };
      res.data.data.records.push(newRow);
      setEmpList(res.data.data.records.reverse());
    });

  }, [emp_id]);

  function searchAttendance(event) {

    setMonthName(moment(from).format("DD/MM/YYYY") + " - " + moment(to).format("DD/MM/YYYY"));

    if (from == "" || from == null || to == "" || to == null) {
      Swal.fire("warning", "Please select date range", "warning");
    } else {
      const monthData = {
        from,
        to,
        emp_id
      };

      setLoading(true);
      getWeekWiseTimeSheet(monthData).then((response) => {
        if (response.length > 0) {
          setColumnsData(response[0].weekly_data);
          setRowData(response);
        } else {
          setColumnsData(response);
          setRowData(response);
        }
        setLoading(false);
      });
    }
  }

  const onChange = (dates, dateStrings) => {
    if (dates) {
      setFilterTo(dateStrings[1]);
      setFilterFrom(dateStrings[0]);
    } else {

      setFilterTo("");
      setFilterFrom("");
    }
  };
  const handleChange = (value) => {
    setEmpID(value);
    searchAttendance();

  };

  const disabledDate = (current) => {
    return current && current > dayjs().endOf('day');
  };

  const viewMainData = (viewData) => {
    setEditEmpID(viewData.emp_id);
    setEditEmpName(viewData.name);
    setWeekWiseDataset(viewData.weekly_data);
    setViewModalTitle("" + viewData.name + " - Work Logs (" + moment(from).format("DD/MM/YYYY") + " - " + moment(to).format("DD/MM/YYYY") + ")")

    setViewModalVisible(true);
  }

  const editableDataView = (data) => {
    setEditModalTitle(editEmpName + " - " + moment(data.week_start_date).format("DD/MM/YYYY") + " - " + moment(data.week_end_date).format("DD/MM/YYYY") + "");
    const edit_request = { emp_id: editEmpID, start_date: data.week_start_date, end_date: data.week_end_date }
    getWeekTimeLogData(edit_request).then((response) => {

      setweekEditDataset(response);

    });

    setEditModalVisible(true);
  }


  return (
    <>

      <div className="users-list-filter px-2 align-center">
        <form>
          <div className="row border rounded py-2 mb-2 mx-n2">

            <div className="col-12 col-sm-6 col-lg-3">
              <label for="users-list-role">Employee Selection</label>
              <Select
                defaultValue="0"
                style={{
                  width: '100%',
                }}
                onChange={handleChange}
                options={empList}
                value={emp_id}
              />
            </div>
            <div className="col-12 col-sm-6 col-lg-3"></div>
            <div className="col-12 col-sm-6 col-lg-4">
              <label for="users-list-role">Date Period</label>
              <fieldset className="form-group">
                <RangePicker
                  className="form-control"
                  disabledDate={disabledDate}
                  ranges={{
                    Today: [moment(), moment()],
                    "This Month": [
                      moment().startOf("month"),
                      moment(),
                    ],
                    "Last 7 Days": [
                      moment().subtract(7, 'd'), moment()
                    ]
                  }}
                  onChange={onChange}
                />
              </fieldset>
            </div>

            <div className="col-12 col-sm-6 col-lg-2 d-flex align-items-center">
              <button
                type="button"
                className="btn btn-primary btn-block users-list-clear glow mb-0"
                onClick={searchAttendance}
              >
                <i className="ft-search"></i> Search
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-content">
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <div className="table-responsive">
                      <div className="table">
                        <Table
                          loading={loading}
                          columns={mainColumns}
                          dataSource={rowData}
                          pagination={{
                            pageSize: 20,
                            showSizeChanger: true,
                            position: ["bottomCenter"],
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title={viewModalTitle}
        closable={false}
        visible={viewModalVisible}
        onOk={() => setViewModalVisible(false)}
        footer={[

          <Button
            key="button"
            type="warning"
          >
            <CSVLink
              filename={`${editEmpName}` + " - attendance.csv"}
              data={weekWiseDataset}
            >
              <i class="ft-download"></i> &nbsp;
              Export
            </CSVLink>
          </Button>,
          <Button key="back" onClick={() => setViewModalVisible(false)}>
            Close
          </Button>,
        ]}
      >
        <div className="row">
          <div className="col-12">
            <div className="table-responsive">
              <div className="table">
                <Table
                  columns={weeklyDataColumns}
                  dataSource={weekWiseDataset}
                  pagination={{
                    pageSize: 20,
                    showSizeChanger: true,
                    position: ["bottomCenter"],
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>


      <Modal
        title={editModalTitle}
        closable={false}
        width={800}
        visible={editModalVisible}
        onOk={() => setEditModalVisible(false)}
        onCancel={() => setEditModalVisible(false)}>
        <div className="row">
          <div className="col-12">
            <div className="table-responsive">
              <div className="table">

                <Form form={form} component={false}>
                  <Table
                    components={{
                      body: {
                        cell: EditableCell,
                      },
                    }}
                    bordered
                    dataSource={weekEditDataset}
                    columns={mergedColumns}
                    rowClassName="editable-row"
                    pagination={{
                      onChange: cancel,
                    }}
                  />
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
export default HourlyAttendance;
