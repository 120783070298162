import React, { useState, useEffect } from "react";
import { getLoggedUserDetails } from "../services/AuthAPIServices";

function Footer() {
  const user_id = localStorage.getItem("hms_user_id");
  const [company_full_name, setCompanyName] = useState("");
  const [website, setWebsite] = useState("");
  useEffect(() => {
    const user = {
      user_id,
    };
    getLoggedUserDetails(user).then((response) => {
      setCompanyName(response.data.company.company_full_name??'Archnix');
      setWebsite(response.data.company.website??"https://archnix.com/");
    });
  }, []);
  return (
    <footer className="footer">
      <p className="clearfix text-muted m-0">
        <span>Copyright &copy; {new Date().getFullYear()} &nbsp;</span>
        <a href={website?website:'https://archnix.com/'} id="pixinventLink" target="_blank">
          {company_full_name?company_full_name:'Archnix'}
        </a>
        <span className="d-none d-sm-inline-block">, All rights reserved.</span>
      </p>
    </footer>
  );
}

export default Footer;
