import React, { useState, useEffect } from "react";

import Swal from "sweetalert2";
import { Table, Modal, Input, Button } from "antd";
import "antd/dist/antd.css";
import { SearchOutlined } from "@ant-design/icons";
import {
  getAllDesignations,
  designationActions,
} from "../../services/MasterFileAPIServices";

function Designation() {
  const spinner = document.getElementById("spinner");
  const [tableData, setTableData] = useState([]);
  const [designation_name, setDesignation] = useState("");
  const [status, setStatus] = useState("");
  const [designationId, setDesignationId] = useState("0");
  const [modalTitle, setModalTitle] = useState("Add New Designation");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const columns = [
    {
      key: "designation",
      title: "Designation",
      dataIndex: "designation_name",
      filterMode: "tree",
      filterSearch: true,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type designation"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropDown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.designation_name
          .toLowerCase()
          .includes(value.toLowerCase());
      },
    },
    {
      key: "status",
      title: "Status",
      render: (record) => {
        return (
          <>
            {" "}
            <div className="flex">
              {record.status == 1 ? (
                <span className="badge bg-light-success">Active</span>
              ) : (
                <span className="badge bg-light-danger">Inactive</span>
              )}
            </div>
          </>
        );
      },
    },
    {
      key: "action",
      title: "Actions",
      render: (record) => {
        return (
          <>
            <div className="flex">
              <button
                className="badge badge-pill bg-light-danger action-btn"
                onClick={() => Edit(record)}
              >
                <i class="ft-edit"></i>
              </button>
            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    getAllDesignations().then((response) => {
      spinner.style.display = "none";
      setTableData(response);
      setLoading(false);
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { designation_name, status, designationId };
    designationActions(data).then((res) => {
      if (res.status_code == 1) {
        Swal.fire("success", "Successfully updated", "success");
        setDesignationId(0);
        setDesignation("");
        setStatus(1);
        setModalTitle("Add New Designation");
        setVisible(false);
        setLoading(true);
        getAllDesignations().then((response) => {
          setTableData(response);
          setLoading(false);
        });
      } else {
        Swal.fire("warning", " " + res.message, "warning");
      }
    });
  };

  const handleNewDesignationForm = (e) => {
    e.preventDefault();
    setDesignationId(0);
    setDesignation("");
    setStatus(1);
    setModalTitle("Add New Designation");
    setVisible(true);
  };

  const Edit = (data) => {
    setDesignationId(data.id);
    setDesignation(data.designation_name);
    setStatus(data.status);
    setModalTitle("Edit Designation");
    setVisible(true);
  };
  return (
    <>
      <div className="row">
        <div className="col-8">
          <h3> Designation Master File</h3>
        </div>
        <div className="col-4">
          <button
            type="button"
            className="btn btn-primary mb-3 d-block  float-right"
            onClick={(e) => handleNewDesignationForm(e)}
          >
            Add New<i class="ft-plus ml-2"></i>
          </button>
        </div>
      </div>
      <section className="users-list-wrapper">
        <div className="users-list-table">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                    <div className="table-responsive">
                      <div className="table">
                        <Table
                          loading={loading}
                          dataSource={tableData}
                          columns={columns}
                          pagination={{
                            pageSize: 10,
                            showSizeChanger: true,
                            position: ["bottomCenter"],
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        title={modalTitle}
        visible={visible}
        okText="Save"
        onCancel={() => setVisible(false) }
        onOk={(e) =>  {
          handleSubmit(e);
        }}
      >
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <label className="form-label">
            Designation Name <span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            name="designation_name"
            id="designation_name"
            value={designation_name}
            onChange={(e) => setDesignation(e.target.value)}
            required
          />
          <label className="form-label">
            Status <span style={{ color: "red" }}>*</span>
          </label>
          <select
            className="form-control"
            name="status"
            id="status"
            value={status}
            required
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="1"> Active</option>
            <option value="0"> Inactive</option>
          </select>
          <input
            type="hidden"
            className="form-control"
            name="designationId"
            id="designationId"
            value={designationId}
            onChange={(e) => setDesignationId(e.target.value)}
            required
          />
        </form>
      </Modal>

    </>
  );
}
export default Designation;
