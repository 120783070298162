import React, { useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { checkAccess } from "../services/PermissionAPIServices";

const ProtectedRoute = ({ path, element: Component }) => {
  const [hasAccess, setHasAccess] = useState(null);
  const [hasToken, setHasToken] = useState(localStorage.getItem("hms_access_token"));

  useEffect(() => {
    const verifyAccess = async () => {
      const permission_role = {
        current_path:path,
      };
      checkAccess(permission_role).then((res) => {
        if(res?.data?.access !== undefined){
          setHasAccess(res.data.access);
        }else{
          setHasAccess(false)
        }
       });
    };
    path == 'account' ? setHasAccess(true) : verifyAccess();
    setHasToken(localStorage.getItem("hms_access_token") ?? false);
  }, [path]);

  if (hasAccess == null) {
    return (
      <div>
        Loading...
      </div>
    );
  }

  return (<>
    {hasAccess && hasToken ? <Component /> : hasToken ? <Navigate to="/unauthorized" />:<Navigate to="/login" />}
    </>) 
};

export default ProtectedRoute;
