import React, { useState, useEffect } from "react";

import Swal from "sweetalert2";
import { Table, Modal, Input, Button, TimePicker, Switch } from "antd";
import { SearchOutlined, CheckOutlined, CloseOutlined, } from "@ant-design/icons";
import { createNewUser, getUserList, statusChangeUser, updateUser } from "../services/UserAPIServices";
import { displayUrl } from "../UrlPaths";
import moment from "moment";
import { getUserRoles } from "../services/PermissionAPIServices";
import { SUPER_ADMIN_ROLE } from "../AppConstant";

function UserManagement() {
  const [tableData, setTableData] = useState([]);
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [user_role, setUserRole] = useState("");
  const [email, setEmail] = useState("");
  const [user_id, setUserId] = useState("0");
  const [modalTitle, setModalTitle] = useState("Add New Branch");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [userRoleList, setUserRoeList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  const columns = [
    {
      key: "name_with_initial",
      title: "name",
      render: (record) => {
        return (
          <>
            {" "}
            <div className="d-flex">
              <img className="img-fluid" width={30}
                src={`${displayUrl}/${record.profile_img_path}`} /><b>&nbsp; {record.name_with_initial}</b>
            </div>
          </>
        );
      },
    },
    {
      key: "email",
      title: "Email",
      dataIndex: "email"
    },
    {
      key: "role_name",
      title: "User Role",
      dataIndex: "role_name"
    },
    {
      key: "created_at",
      title: "Created Date",
      dataIndex: "created_at",
      render: (record) => moment(record).format("DD/MM/YYYY"),
    },
    {
      key: "status",
      title: "Status",
      render: (record) => {
        return (
          <>
            {" "}
            <div className="d-flex">
              <div>
                {record.status == "1" ? (
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    defaultChecked
                    onClick={() => handleStatusChange("0", record.id)}
                  />
                ) : (
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    defaultUnChecked
                    onClick={() => handleStatusChange("1", record.id)}
                  />
                )}
              </div>
            </div>
          </>
        );
      },
    },
    {
      key: "action",
      title: "",
      render: (record) => {
        return (
          <>
            <div className="d-flex">
              <button
                className="badge badge-pill bg-light-info mb-1 mr-2"
                style={{ border: '2px solid #dadada' }}
                onClick={() => Edit(record)}
              >
                <i className="ft-edit"></i>
              </button>

            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    loadTableData();
  }, []);


  const loadTableData = () => {
    setVisible(false);
    setLoading(true);
    getUserList().then((response) => {

      setTableData(response.data)
      setLoading(false);

    }).catch((error) => { console.error(error) });

    getUserRoles().then((response) => {
      setUserRoeList(response);
    });

  }


  const handleSubmit = (e) => {
    e.preventDefault();

    let inputValidation = true;

    if (first_name == "" || first_name == null) {
      Swal.fire("Warning", "Please enter first name", "warning");
      inputValidation = false;
    } else if (last_name == "" || last_name == null) {
      Swal.fire("Warning", "Please enter last name", "warning");
      inputValidation = false;
    }else if(email == "" || email == null){
      Swal.fire("Warning", "Please enter email", "warning");
      inputValidation = false;
    }else if(user_role == ""){
      Swal.fire("Warning", "Please select user role", "warning");
      inputValidation = false;
    }
    const data = { first_name, last_name, user_role, user_id, email };

    if (user_id == "0" && inputValidation) {
      setConfirmLoading(true);
      createNewUser(data).then((response) => {
        console.log(response)
        if (response.status_code == 1) {
          Swal.fire("Success", "" + response.message, "success").then(function () {
            setConfirmLoading(false);
            loadTableData();
          });

        } else {
          setConfirmLoading(false);
          Swal.fire("Warning", " " + response.message, "warning");
        }
      }).catch((error) => {
        setConfirmLoading(false);
        Swal.fire("Warning", "" + error.message, "warning");
      });
    } else if (inputValidation) {
      setConfirmLoading(true);

      updateUser(data).then((response) => {
        if (response.status_code == 1) {
          Swal.fire("Success", "" + response.message, "success").then(function () {
            setConfirmLoading(false);
            loadTableData();
          });
        } else {
          setConfirmLoading(false);
          Swal.fire("Warning", " " + response.message, "warning");
        }
      }).catch((error) => {
        setConfirmLoading(false);
        Swal.fire("Warning", "" + error.message, "warning");
       
      });
    }
  };

  const handleNewUserForm = (e) => {
    setConfirmLoading(false);
    setUserId("0");
    setFirstName("");
    setLastName("");
    setEmail("");
    setUserRole("");
    setModalTitle("Add New User");
    setIsEdit(false);
    setVisible(true);

  };

  const Edit = (data) => {
    setConfirmLoading(false);
    setUserId(data.id);
    setFirstName(data.first_name);
    setEmail(data.email);
    setUserRole(data.user_role);
    setModalTitle("Edit User - " + data.email);
    setLastName(data.last_name);
    setIsEdit(true);
    setVisible(true);

  };

  const handleStatusChange = (status, userID) => {
    const data = { status: status, id: userID };
    statusChangeUser(userID, data).then((response) => {
      if (response.status_code == 1) {
        Swal.fire("Success", "" + response.message, "success");
      } else {
        Swal.fire("Warning", " " + response.message, "warning");
      }
      loadTableData();
    });
  }

  return (
    <>
      <div className="row">
        <div className="col-8">
          <h3> System Users </h3>
        </div>
        <div className="col-4">
          <button
            type="button"
            className="btn btn-primary mb-3 d-block  float-right"
            onClick={(e) => handleNewUserForm(e)}
          >
            Add New <i class="ft-plus ml-2"></i>
          </button>
        </div>
      </div>
      <section id="simple-table">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-content">
                <div className="card-body">
                  <div className="table-responsive">
                    <div className="table">
                      <Table
                        loading={loading}
                        dataSource={tableData}
                        columns={columns}
                        pagination={{
                          pageSize: 10,
                          position: ["bottomCenter"],
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        title={modalTitle}
        visible={visible}
        okText="Save"
        confirmLoading={confirmLoading}
        onCancel={() => setVisible(false)}
        onOk={(e) => {
          handleSubmit(e);
        }}
      >
        <form>
          <div className="row">
            <div className="col-md-12">

              <label className="form-label">
                First Name <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="First Name"
                value={first_name}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />

            </div>


            <div className="col-md-12">
              <label className="form-label">
                Last Name <span style={{ color: "red" }}>*</span>
              </label>
              <input
                className="form-control"
                placeholder="Last Name"
                onChange={(e) => setLastName(e.target.value)}
                value={last_name}
                required />
            </div>
            {isEdit ? (<></>):(<>
              <div className="col-md-12">
              <label className="form-label">
                Email <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            </>)}

            <div className="col-md-6">
              <label className="form-label">
                User Role <span style={{ color: "red" }}>*</span>
              </label>
              <select
                className="form-control"
                value={user_role}
                required
                onChange={(e) => setUserRole(e.target.value)}
              >
                <option value="" disabled>
                  Select user role
                </option>
                {userRoleList.map((item, i) => {
                  return (<>
                    {item.id == SUPER_ADMIN_ROLE ? (<></>):(<>
                      <option value={item.id}>
                      {item.role_name}
                    </option>
                    </>)}
                    </>);
                })}
              </select>
            </div>

          </div>
        </form>
      </Modal>
    </>
  );
}
export default UserManagement;
